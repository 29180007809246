export const centroidsData = [
  {
    LAT: 33,
    LONG: 66,
    DMS_LAT: 330000,
    DMS_LONG: 660000,
    MGRS: '42STB1970055286',
    JOG: 'NI42-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AF',
    SHORT_NAME: 'Afghanistan',
    FULL_NAME: 'Islamic Republic of Afghanistan',
    MOD_DATE: '2009-04-10',
    ISO3136: 'AF',
  },
  {
    LAT: 41,
    LONG: 20,
    DMS_LAT: 410000,
    DMS_LONG: 200000,
    MGRS: '34TDL1589839239',
    JOG: 'NK34-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AL',
    SHORT_NAME: 'Albania',
    FULL_NAME: 'Republic of Albania',
    MOD_DATE: '2007-02-28',
    ISO3136: 'AL',
  },
  {
    LAT: 28,
    LONG: 3,
    DMS_LAT: 280000,
    DMS_LONG: 30000,
    MGRS: '31REL0000097202',
    JOG: 'NH31-15',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AG',
    SHORT_NAME: 'Algeria',
    FULL_NAME: "People's Democratic Republic of Algeria",
    MOD_DATE: '2011-03-03',
    ISO3136: 'DZ',
  },
  {
    LAT: -14.3333333,
    LONG: -170,
    DMS_LAT: -142000,
    DMS_LONG: -1700000,
    MGRS: 1802701,
    JOG: '',
    DSG: 'PCLD',
    AFFIL: 'US',
    FIPS10: 'AS',
    SHORT_NAME: 'American Samoa',
    FULL_NAME: 'Territory of American Samoa',
    MOD_DATE: '1998-10-06',
    ISO3136: 'AS',
  },
  {
    LAT: 42.5,
    LONG: 1.5,
    DMS_LAT: 423000,
    DMS_LONG: 13000,
    MGRS: '31TCH7675006383',
    JOG: 'NK31-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AN',
    SHORT_NAME: 'Andorra',
    FULL_NAME: 'Principality of Andorra',
    MOD_DATE: '2007-02-28',
    ISO3136: 'AD',
  },
  {
    LAT: -12.5,
    LONG: 18.5,
    DMS_LAT: -123000,
    DMS_LONG: 183000,
    MGRS: '34LBM2828516873',
    JOG: 'SD34-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AO',
    SHORT_NAME: 'Angola',
    FULL_NAME: 'Republic of Angola',
    MOD_DATE: '2007-02-28',
    ISO3136: 'AO',
  },
  {
    LAT: 18.216667,
    LONG: -63.05,
    DMS_LAT: 181300,
    DMS_LONG: -630300,
    MGRS: '20QMF9471314158',
    JOG: 'NE20-06',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'AV',
    SHORT_NAME: 'Anguilla',
    FULL_NAME: 'Anguilla',
    MOD_DATE: '1993-12-22',
    ISO3136: 'AI',
  },
  {
    LAT: 17.05,
    LONG: -61.8,
    DMS_LAT: 170300,
    DMS_LONG: -614800,
    MGRS: '20QPD2770685478',
    JOG: 'NE20-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AC',
    SHORT_NAME: 'Antigua and Barbuda',
    FULL_NAME: 'Antigua and Barbuda',
    MOD_DATE: '1997-08-29',
    ISO3136: 'AG',
  },
  {
    LAT: -34,
    LONG: -64,
    DMS_LAT: -340000,
    DMS_LONG: -640000,
    MGRS: '20HMH0765037393',
    JOG: 'SI20-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AR',
    SHORT_NAME: 'Argentina',
    FULL_NAME: 'Argentine Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'AR',
  },
  {
    LAT: 40,
    LONG: 45,
    DMS_LAT: 400000,
    DMS_LONG: 450000,
    MGRS: '38TNK0000027757',
    JOG: 'NK38-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AM',
    SHORT_NAME: 'Armenia',
    FULL_NAME: 'Republic of Armenia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'AM',
  },
  {
    LAT: 12.5,
    LONG: -69.966667,
    DMS_LAT: 123000,
    DMS_LONG: -695800,
    MGRS: '19PCP9496382035',
    JOG: 'ND19-14',
    DSG: 'PCLIX',
    AFFIL: 'NL',
    FIPS10: 'AA',
    SHORT_NAME: 'Aruba',
    FULL_NAME: 'Aruba',
    MOD_DATE: '1993-12-21',
    ISO3136: 'AW',
  },
  {
    LAT: -15.95,
    LONG: -5.7,
    DMS_LAT: -155700,
    DMS_LONG: -54200,
    MGRS: '30LTH1096034723',
    JOG: 'SD30-13',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'SH',
    SHORT_NAME: 'Ascension',
    FULL_NAME: 'Ascension and Tristan da Cunha Saint Helena',
    MOD_DATE: '1993-12-22',
    ISO3136: 'SH',
  },
  {
    LAT: -12.416667,
    LONG: 123.333333,
    DMS_LAT: -122500,
    DMS_LONG: 1232000,
    MGRS: '51LWG3623027349',
    JOG: 'SD51-03',
    DSG: 'PCLD',
    AFFIL: 'AS',
    FIPS10: 'AT',
    SHORT_NAME: 'Ashmore and Cartier Islands',
    FULL_NAME: 'Territory of Ashmore and Cartier Islands',
    MOD_DATE: '2007-02-28',
    ISO3136: '',
  },
  {
    LAT: -25,
    LONG: 135,
    DMS_LAT: -250000,
    DMS_LONG: 1350000,
    MGRS: '53JNN0000035052',
    JOG: 'SG53-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AS',
    SHORT_NAME: 'Australia',
    FULL_NAME: 'Commonwealth of Australia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'AU',
  },
  {
    LAT: 47.333333,
    LONG: 13.333333,
    DMS_LAT: 472000,
    DMS_LONG: 132000,
    MGRS: '33TUN7408243554',
    JOG: 'NL33-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AU',
    SHORT_NAME: 'Austria',
    FULL_NAME: 'Republic of Austria',
    MOD_DATE: '2007-03-20',
    ISO3136: 'AT',
  },
  {
    LAT: 40.5,
    LONG: 47.5,
    DMS_LAT: 403000,
    DMS_LONG: 473000,
    MGRS: '38TQK1184586257',
    JOG: 'NK38-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AJ',
    SHORT_NAME: 'Azerbaijan',
    FULL_NAME: 'Republic of Azerbaijan',
    MOD_DATE: '2007-02-28',
    ISO3136: 'AZ',
  },
  {
    LAT: 24,
    LONG: -76,
    DMS_LAT: 240000,
    DMS_LONG: -760000,
    MGRS: '18RUM9828554588',
    JOG: 'NG18-14',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BF',
    SHORT_NAME: 'Bahamas',
    FULL_NAME: 'Commonwealth of The Bahamas',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BS',
  },
  {
    LAT: 26,
    LONG: 50.5,
    DMS_LAT: 260000,
    DMS_LONG: 503000,
    MGRS: '39RVJ4996175780',
    JOG: 'NG39-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BA',
    SHORT_NAME: 'Bahrain',
    FULL_NAME: 'Kingdom of Bahrain',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BH',
  },
  {
    LAT: 24,
    LONG: 90,
    DMS_LAT: 240000,
    DMS_LONG: 900000,
    MGRS: '46RAM9477357479',
    JOG: 'NG46-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BG',
    SHORT_NAME: 'Bangladesh',
    FULL_NAME: "People's Republic of Bangladesh",
    MOD_DATE: '2007-02-28',
    ISO3136: 'BD',
  },
  {
    LAT: 13.166667,
    LONG: -59.533333,
    DMS_LAT: 131000,
    DMS_LONG: -593200,
    MGRS: '21PTQ2538656950',
    JOG: 'ND21-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BB',
    SHORT_NAME: 'Barbados',
    FULL_NAME: 'Barbados',
    MOD_DATE: '1997-08-29',
    ISO3136: 'BB',
  },
  {
    LAT: -21.416667,
    LONG: 39.7,
    DMS_LAT: -212500,
    DMS_LONG: 394200,
    MGRS: '37KES7254831576',
    JOG: 'SF37-07',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'BS',
    SHORT_NAME: 'Bassas da India',
    FULL_NAME: 'Bassas da India',
    MOD_DATE: '1993-12-21',
    ISO3136: '',
  },
  {
    LAT: 53,
    LONG: 28,
    DMS_LAT: 530000,
    DMS_LONG: 280000,
    MGRS: '35UNU6710972738',
    JOG: 'NN35-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BO',
    SHORT_NAME: 'Belarus',
    FULL_NAME: 'Republic of Belarus',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BY',
  },
  {
    LAT: 50.833333,
    LONG: 4,
    DMS_LAT: 505000,
    DMS_LONG: 40000,
    MGRS: '31UES7042031768',
    JOG: 'NM31-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BE',
    SHORT_NAME: 'Belgium',
    FULL_NAME: 'Kingdom of Belgium',
    MOD_DATE: '2011-11-29',
    ISO3136: 'BE',
  },
  {
    LAT: 17.25,
    LONG: -88.75,
    DMS_LAT: 171500,
    DMS_LONG: -884500,
    MGRS: '16QCE1394908054',
    JOG: 'NE16-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BH',
    SHORT_NAME: 'Belize',
    FULL_NAME: 'Belize',
    MOD_DATE: '1997-08-29',
    ISO3136: 'BZ',
  },
  {
    LAT: 9.5,
    LONG: 2.25,
    DMS_LAT: 93000,
    DMS_LONG: 21500,
    MGRS: '31PDL1767950220',
    JOG: 'NC31-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BN',
    SHORT_NAME: 'Benin',
    FULL_NAME: 'Republic of Benin',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BJ',
  },
  {
    LAT: 32.333333,
    LONG: -64.75,
    DMS_LAT: 322000,
    DMS_LONG: -644500,
    MGRS: '20SLA3529378730',
    JOG: 'NI20-13',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'BD',
    SHORT_NAME: 'Bermuda',
    FULL_NAME: 'Bermuda',
    MOD_DATE: '1993-12-22',
    ISO3136: 'BM',
  },
  {
    LAT: 27.5,
    LONG: 90.5,
    DMS_LAT: 273000,
    DMS_LONG: 903000,
    MGRS: '46RBR5302344306',
    JOG: 'NG46-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BT',
    SHORT_NAME: 'Bhutan',
    FULL_NAME: 'Kingdom of Bhutan',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BT',
  },
  {
    LAT: -17,
    LONG: -65,
    DMS_LAT: -170000,
    DMS_LONG: -650000,
    MGRS: '20KKG8707719358',
    JOG: 'SE20-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BL',
    SHORT_NAME: 'Bolivia',
    FULL_NAME: 'Plurinational State of Bolivia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BO',
  },
  {
    LAT: 12.2,
    LONG: -68.25,
    DMS_LAT: 121200,
    DMS_LONG: -681500,
    MGRS: '19PEP8158648782',
    JOG: 'ND19-15',
    DSG: 'ADMD',
    AFFIL: 'NL',
    FIPS10: 'NT',
    SHORT_NAME: 'Bonaire',
    FULL_NAME: 'Bonaire, Sint Eustatius, and Saba',
    MOD_DATE: '2010-11-03',
    ISO3136: 'BQ',
  },
  {
    LAT: 44.25,
    LONG: 17.833333,
    DMS_LAT: 441500,
    DMS_LONG: 175000,
    MGRS: '33TYK2620603545',
    JOG: 'NL33-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BK',
    SHORT_NAME: 'Bosnia and Herzegovina',
    FULL_NAME: 'Bosnia and Herzegovina',
    MOD_DATE: '2005-11-23',
    ISO3136: 'BA',
  },
  {
    LAT: -22,
    LONG: 24,
    DMS_LAT: -220000,
    DMS_LONG: 240000,
    MGRS: '35KJR9023564133',
    JOG: 'SF35-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BC',
    SHORT_NAME: 'Botswana',
    FULL_NAME: 'Republic of Botswana',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BW',
  },
  {
    LAT: -54.433333,
    LONG: 3.4,
    DMS_LAT: -542600,
    DMS_LONG: 32400,
    MGRS: '31FEV2594768190',
    JOG: 'SN31-08',
    DSG: 'PCLD',
    AFFIL: 'NO',
    FIPS10: 'BV',
    SHORT_NAME: 'Bouvet Island',
    FULL_NAME: 'Bouvet Island',
    MOD_DATE: '1993-12-21',
    ISO3136: 'BV',
  },
  {
    LAT: -10,
    LONG: -55,
    DMS_LAT: -100000,
    DMS_LONG: -550000,
    MGRS: '21LYJ1923393923',
    JOG: 'SC21-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BR',
    SHORT_NAME: 'Brazil',
    FULL_NAME: 'Federative Republic of Brazil',
    MOD_DATE: '2007-03-09',
    ISO3136: 'BR',
  },
  {
    LAT: -6,
    LONG: 72,
    DMS_LAT: -60000,
    DMS_LONG: 720000,
    MGRS: '43MAP6784235886',
    JOG: 'SB43-05',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'IO',
    SHORT_NAME: 'British Indian Ocean Territory',
    FULL_NAME: 'British Indian Ocean Territory',
    MOD_DATE: '1993-12-22',
    ISO3136: 'IO',
  },
  {
    LAT: 18.5,
    LONG: -64.5,
    DMS_LAT: 183000,
    DMS_LONG: -643000,
    MGRS: '20QLF4164546163',
    JOG: 'NE20-06',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'VI',
    SHORT_NAME: 'British Virgin Islands',
    FULL_NAME: 'British Virgin Islands',
    MOD_DATE: '1993-12-22',
    ISO3136: 'VG',
  },
  {
    LAT: 4.5,
    LONG: 114.666667,
    DMS_LAT: 43000,
    DMS_LONG: 1144000,
    MGRS: '50NKK4108297809',
    JOG: 'NB50-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BX',
    SHORT_NAME: 'Brunei',
    FULL_NAME: 'Negara Brunei Darussalam',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BN',
  },
  {
    LAT: 43,
    LONG: 25,
    DMS_LAT: 430000,
    DMS_LONG: 250000,
    MGRS: '35TLH3698162756',
    JOG: 'NK35-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BU',
    SHORT_NAME: 'Bulgaria',
    FULL_NAME: 'Republic of Bulgaria',
    MOD_DATE: '2011-11-29',
    ISO3136: 'BG',
  },
  {
    LAT: 13,
    LONG: -2,
    DMS_LAT: 130000,
    DMS_LONG: -20000,
    MGRS: '30PXV0844637349',
    JOG: 'ND30-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'UV',
    SHORT_NAME: 'Burkina Faso',
    FULL_NAME: 'Burkina Faso',
    MOD_DATE: '2001-03-21',
    ISO3136: 'BF',
  },
  {
    LAT: 22,
    LONG: 98,
    DMS_LAT: 220000,
    DMS_LONG: 980000,
    MGRS: '47QLE9677533164',
    JOG: 'NF47-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BM',
    SHORT_NAME: 'Burma',
    FULL_NAME: 'Union of Burma',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MM',
  },
  {
    LAT: -3.5,
    LONG: 30,
    DMS_LAT: -33000,
    DMS_LONG: 300000,
    MGRS: '36MSB6664112607',
    JOG: 'SA36-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BY',
    SHORT_NAME: 'Burundi',
    FULL_NAME: 'Republic of Burundi',
    MOD_DATE: '2007-02-28',
    ISO3136: 'BI',
  },
  {
    LAT: 13,
    LONG: 105,
    DMS_LAT: 130000,
    DMS_LONG: 1050000,
    MGRS: '48PWV0000037136',
    JOG: 'ND48-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CB',
    SHORT_NAME: 'Cambodia',
    FULL_NAME: 'Kingdom of Cambodia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KH',
  },
  {
    LAT: 6,
    LONG: 12,
    DMS_LAT: 60000,
    DMS_LONG: 120000,
    MGRS: '33NSG6784264114',
    JOG: 'NB33-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CM',
    SHORT_NAME: 'Cameroon',
    FULL_NAME: 'Republic of Cameroon',
    MOD_DATE: '2011-11-29',
    ISO3136: 'CM',
  },
  {
    LAT: 60,
    LONG: -96,
    DMS_LAT: 600000,
    DMS_LONG: -960000,
    MGRS: '15VUG3270555205',
    JOG: 'NP15-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CA',
    SHORT_NAME: 'Canada',
    FULL_NAME: 'Canada',
    MOD_DATE: '1997-08-29',
    ISO3136: 'CA',
  },
  {
    LAT: 16,
    LONG: -24,
    DMS_LAT: 160000,
    DMS_LONG: -240000,
    MGRS: '27QST7890071254',
    JOG: 'NE27-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CV',
    SHORT_NAME: 'Cape Verde',
    FULL_NAME: 'Republic of Cape Verde',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CV',
  },
  {
    LAT: 19.5,
    LONG: -80.666667,
    DMS_LAT: 193000,
    DMS_LONG: -804000,
    MGRS: '17QNB3497756187',
    JOG: 'NE17-03',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'CJ',
    SHORT_NAME: 'Cayman Islands',
    FULL_NAME: 'Cayman Islands',
    MOD_DATE: '1997-08-29',
    ISO3136: 'KY',
  },
  {
    LAT: 7,
    LONG: 21,
    DMS_LAT: 70000,
    DMS_LONG: 210000,
    MGRS: '34NEN0000073749',
    JOG: 'NB34-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CT',
    SHORT_NAME: 'Central African Republic',
    FULL_NAME: 'Central African Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CF',
  },
  {
    LAT: 15,
    LONG: 19,
    DMS_LAT: 150000,
    DMS_LONG: 190000,
    MGRS: '34PBB8494759298',
    JOG: 'ND34-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CD',
    SHORT_NAME: 'Chad',
    FULL_NAME: 'Republic of Chad',
    MOD_DATE: '2007-02-28',
    ISO3136: 'TD',
  },
  {
    LAT: -30,
    LONG: -71,
    DMS_LAT: -300000,
    DMS_LONG: -710000,
    MGRS: '19JCG0708579531',
    JOG: 'SH19-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CI',
    SHORT_NAME: 'Chile',
    FULL_NAME: 'Republic of Chile',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CL',
  },
  {
    LAT: 35,
    LONG: 105,
    DMS_LAT: 350000,
    DMS_LONG: 1050000,
    MGRS: '48SWD0000073043',
    JOG: 'NI48-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CH',
    SHORT_NAME: 'China',
    FULL_NAME: "People's Republic of China",
    MOD_DATE: '1997-11-18',
    ISO3136: 'CN',
  },
  {
    LAT: -10.5,
    LONG: 105.666667,
    DMS_LAT: -103000,
    DMS_LONG: 1054000,
    MGRS: '48LWP7295139228',
    JOG: 'SC48-11',
    DSG: 'PCLD',
    AFFIL: 'AS',
    FIPS10: 'KT',
    SHORT_NAME: 'Christmas Island',
    FULL_NAME: 'Territory of Christmas Island',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CX',
  },
  {
    LAT: 10.283333,
    LONG: -109.216667,
    DMS_LAT: 101700,
    DMS_LONG: -1091300,
    MGRS: '12PXS9530337282',
    JOG: 'NC12-08',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'IP',
    SHORT_NAME: 'Clipperton Island',
    FULL_NAME: 'Clipperton Island',
    MOD_DATE: '1997-07-12',
    ISO3136: '',
  },
  {
    LAT: -12,
    LONG: 96.833333,
    DMS_LAT: -120000,
    DMS_LONG: 965000,
    MGRS: '47LKG6408772519',
    JOG: 'SC47-13',
    DSG: 'PCLD',
    AFFIL: 'AS',
    FIPS10: 'CK',
    SHORT_NAME: 'Cocos (Keeling) Islands',
    FULL_NAME: 'Territory of Cocos (Keeling) Islands',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CC',
  },
  {
    LAT: 4,
    LONG: -72,
    DMS_LAT: 40000,
    DMS_LONG: -720000,
    MGRS: '19NAE6683142736',
    JOG: 'NB19-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CO',
    SHORT_NAME: 'Colombia',
    FULL_NAME: 'Republic of Colombia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CO',
  },
  {
    LAT: -12.166667,
    LONG: 44.25,
    DMS_LAT: -121000,
    DMS_LONG: 441500,
    MGRS: '38LMM1840454904',
    JOG: 'SD38-02',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CN',
    SHORT_NAME: 'Comoros',
    FULL_NAME: 'Union of the Comoros',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KM',
  },
  {
    LAT: -16.083333,
    LONG: -161.583333,
    DMS_LAT: -160500,
    DMS_LONG: -1613500,
    MGRS: '04KBH2364020120',
    JOG: 'SE04-01',
    DSG: 'PCLS',
    AFFIL: 'NZ',
    FIPS10: 'CW',
    SHORT_NAME: 'Cook Islands',
    FULL_NAME: 'Cook Islands',
    MOD_DATE: '2007-04-13',
    ISO3136: 'CK',
  },
  {
    LAT: -17.5,
    LONG: 151,
    DMS_LAT: -173000,
    DMS_LONG: 1510000,
    MGRS: '56KKF8765164016',
    JOG: 'SE56-05',
    DSG: 'PCLD',
    AFFIL: 'AS',
    FIPS10: 'CR',
    SHORT_NAME: 'Coral Sea Islands',
    FULL_NAME: 'Coral Sea Islands Territory',
    MOD_DATE: '2007-02-28',
    ISO3136: '',
  },
  {
    LAT: 10,
    LONG: -84,
    DMS_LAT: 100000,
    DMS_LONG: -840000,
    MGRS: '17PJM7107106909',
    JOG: 'NC17-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CS',
    SHORT_NAME: 'Costa Rica',
    FULL_NAME: 'Republic of Costa Rica',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CR',
  },
  {
    LAT: 8,
    LONG: -5,
    DMS_LAT: 80000,
    DMS_LONG: -50000,
    MGRS: '30PTP7955884833',
    JOG: 'NC30-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'IV',
    SHORT_NAME: "Cote d'Ivoire",
    FULL_NAME: "Republic of Cote d'Ivoire",
    MOD_DATE: '2011-05-11',
    ISO3136: 'CI',
  },
  {
    LAT: 45.166667,
    LONG: 15.5,
    DMS_LAT: 451000,
    DMS_LONG: 153000,
    MGRS: '33TWL3929301587',
    JOG: 'NL33-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'HR',
    SHORT_NAME: 'Croatia',
    FULL_NAME: 'Republic of Croatia',
    MOD_DATE: '2011-11-29',
    ISO3136: 'HR',
  },
  {
    LAT: 22,
    LONG: -79.5,
    DMS_LAT: 220000,
    DMS_LONG: -793000,
    MGRS: '17QPE5484433586',
    JOG: 'NF17-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CU',
    SHORT_NAME: 'Cuba',
    FULL_NAME: 'Republic of Cuba',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CU',
  },
  {
    LAT: 12.166667,
    LONG: -69,
    DMS_LAT: 121000,
    DMS_LONG: -690000,
    MGRS: '19PEP0000044983',
    JOG: 'ND19-15',
    DSG: 'PCLIX',
    AFFIL: 'NL',
    FIPS10: 'UC',
    SHORT_NAME: 'Curaçao',
    FULL_NAME: 'Land Curacao',
    MOD_DATE: '2010-11-04',
    ISO3136: 'CW',
  },
  {
    LAT: 35,
    LONG: 33,
    DMS_LAT: 350000,
    DMS_LONG: 330000,
    MGRS: '36SWD0000073043',
    JOG: 'NI36-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CY',
    SHORT_NAME: 'Cyprus',
    FULL_NAME: 'Republic of Cyprus',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CY',
  },
  {
    LAT: 49.75,
    LONG: 15,
    DMS_LAT: 494500,
    DMS_LONG: 150000,
    MGRS: '33UWR0000010835',
    JOG: 'NM33-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'EZ',
    SHORT_NAME: 'Czech Republic',
    FULL_NAME: 'Czech Republic',
    MOD_DATE: '2011-11-29',
    ISO3136: 'CZ',
  },
  {
    LAT: 0,
    LONG: 25,
    DMS_LAT: 0,
    DMS_LONG: 250000,
    MGRS: '35NKA7740500000',
    JOG: 'NA35-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CG',
    SHORT_NAME: 'Democratic Republic of the Congo',
    FULL_NAME: 'Democratic Republic of the Congo',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CD',
  },
  {
    LAT: 56,
    LONG: 10,
    DMS_LAT: 560000,
    DMS_LONG: 100000,
    MGRS: '32VNH6236706531',
    JOG: 'NO32-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'DA',
    SHORT_NAME: 'Denmark',
    FULL_NAME: 'Kingdom of Denmark',
    MOD_DATE: '2007-02-28',
    ISO3136: 'DK',
  },
  {
    LAT: 11.5,
    LONG: 42.5,
    DMS_LAT: 113000,
    DMS_LONG: 423000,
    MGRS: '38PKT2728172452',
    JOG: 'NC38-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'DJ',
    SHORT_NAME: 'Djibouti',
    FULL_NAME: 'Republic of Djibouti',
    MOD_DATE: '2007-02-28',
    ISO3136: 'DJ',
  },
  {
    LAT: 15.5,
    LONG: -61.333333,
    DMS_LAT: 153000,
    DMS_LONG: -612000,
    MGRS: '20PPC7877814324',
    JOG: 'ND20-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'DO',
    SHORT_NAME: 'Dominica',
    FULL_NAME: 'Commonwealth of Dominica',
    MOD_DATE: '2007-02-28',
    ISO3136: 'DM',
  },
  {
    LAT: 19,
    LONG: -70.666667,
    DMS_LAT: 190000,
    DMS_LONG: -704000,
    MGRS: '19QCB2456401658',
    JOG: 'NE19-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'DR',
    SHORT_NAME: 'Dominican Republic',
    FULL_NAME: 'Dominican Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'DO',
  },
  {
    LAT: -2,
    LONG: -77.5,
    DMS_LAT: -20000,
    DMS_LONG: -773000,
    MGRS: '18MTC2189278727',
    JOG: 'SA18-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'EC',
    SHORT_NAME: 'Ecuador',
    FULL_NAME: 'Republic of Ecuador',
    MOD_DATE: '2007-02-28',
    ISO3136: 'EC',
  },
  {
    LAT: 27,
    LONG: 30,
    DMS_LAT: 270000,
    DMS_LONG: 300000,
    MGRS: '36RTQ0227489976',
    JOG: 'NG36-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'EG',
    SHORT_NAME: 'Egypt',
    FULL_NAME: 'Arab Republic of Egypt',
    MOD_DATE: '2007-02-28',
    ISO3136: 'EG',
  },
  {
    LAT: 13.833333,
    LONG: -88.916667,
    DMS_LAT: 135000,
    DMS_LONG: -885500,
    MGRS: '16PBA9283530123',
    JOG: 'ND16-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'ES',
    SHORT_NAME: 'El Salvador',
    FULL_NAME: 'Republic of El Salvador',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SV',
  },
  {
    LAT: 2,
    LONG: 10,
    DMS_LAT: 20000,
    DMS_LONG: 100000,
    MGRS: '32NPH1121321095',
    JOG: 'NA32-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'EK',
    SHORT_NAME: 'Equatorial Guinea',
    FULL_NAME: 'Republic of Equatorial Guinea',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GQ',
  },
  {
    LAT: 15,
    LONG: 39,
    DMS_LAT: 150000,
    DMS_LONG: 390000,
    MGRS: '37PES0000058326',
    JOG: 'ND37-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'ER',
    SHORT_NAME: 'Eritrea',
    FULL_NAME: 'State of Eritrea',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ER',
  },
  {
    LAT: 59,
    LONG: 26,
    DMS_LAT: 590000,
    DMS_LONG: 260000,
    MGRS: '35VMF4254940482',
    JOG: 'NO35-02',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'EN',
    SHORT_NAME: 'Estonia',
    FULL_NAME: 'Republic of Estonia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'EE',
  },
  {
    LAT: 8,
    LONG: 38,
    DMS_LAT: 80000,
    DMS_LONG: 380000,
    MGRS: '37PCJ8979584432',
    JOG: 'NC37-14',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'ET',
    SHORT_NAME: 'Ethiopia',
    FULL_NAME: 'Federal Democratic Republic of Ethiopia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ET',
  },
  {
    LAT: -22.333333,
    LONG: 40.366667,
    DMS_LAT: -222000,
    DMS_LONG: 402200,
    MGRS: '37KFR4074629639',
    JOG: 'SF37-11',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'EU',
    SHORT_NAME: 'Europa Island',
    FULL_NAME: 'Europa Island',
    MOD_DATE: '1996-11-29',
    ISO3136: '',
  },
  {
    LAT: -51.75,
    LONG: -59.166667,
    DMS_LAT: -514500,
    DMS_LONG: -591000,
    MGRS: '21FUC5043864546',
    JOG: 'SM21-10',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'FK',
    SHORT_NAME: 'Falkland Islands',
    FULL_NAME: 'Falkland Islands (Islas Malvinas)',
    MOD_DATE: '1996-12-16',
    ISO3136: 'FK',
  },
  {
    LAT: 62,
    LONG: -7,
    DMS_LAT: 620000,
    DMS_LONG: -70000,
    MGRS: '29VPJ0474375795',
    JOG: 'NP29-06',
    DSG: 'ADM1',
    AFFIL: 'DA',
    FIPS10: 'FO',
    SHORT_NAME: 'Faroe Islands',
    FULL_NAME: 'Faroe Islands',
    MOD_DATE: '1996/12/16',
    ISO3136: 'FO',
  },
  {
    LAT: 5,
    LONG: 152,
    DMS_LAT: 50000,
    DMS_LONG: 1520000,
    MGRS: '56NLL8914052749',
    JOG: 'NB56-10',
    DSG: 'PCLF',
    AFFIL: '',
    FIPS10: 'FM',
    SHORT_NAME: 'Federated States of Micronesia',
    FULL_NAME: 'Federated States of Micronesia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'FM',
  },
  {
    LAT: -18,
    LONG: 178,
    DMS_LAT: -180000,
    DMS_LONG: 1780000,
    MGRS: '60KXF0586709529',
    JOG: 'SE60-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'FJ',
    SHORT_NAME: 'Fiji',
    FULL_NAME: 'Republic of Fiji',
    MOD_DATE: '2007-02-28',
    ISO3136: 'FJ',
  },
  {
    LAT: 64,
    LONG: 26,
    DMS_LAT: 640000,
    DMS_LONG: 260000,
    MGRS: '35WML5108997398',
    JOG: 'NQ35-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'FI',
    SHORT_NAME: 'Finland',
    FULL_NAME: 'Republic of Finland',
    MOD_DATE: '2007-02-28',
    ISO3136: 'FI',
  },
  {
    LAT: 46,
    LONG: 2,
    DMS_LAT: 460000,
    DMS_LONG: 20000,
    MGRS: '31TDL2256894534',
    JOG: 'NL31-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'FR',
    SHORT_NAME: 'France',
    FULL_NAME: 'French Republic',
    MOD_DATE: '2007-03-09',
    ISO3136: 'FR',
  },
  {
    LAT: 4,
    LONG: -53,
    DMS_LAT: 40000,
    DMS_LONG: -530000,
    MGRS: '22NBK7794442398',
    JOG: 'NB22-13',
    DSG: 'ADM1',
    AFFIL: 'FR',
    FIPS10: 'FG',
    SHORT_NAME: 'French Guiana',
    FULL_NAME: 'Department of Guiana',
    MOD_DATE: '2007/02/28',
    ISO3136: 'GF',
  },
  {
    LAT: -15,
    LONG: -140,
    DMS_LAT: -150000,
    DMS_LONG: -1400000,
    MGRS: '07LFD0751241431',
    JOG: 'SD07-11',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'FP',
    SHORT_NAME: 'French Polynesia',
    FULL_NAME: 'French Polynesia',
    MOD_DATE: '2010-11-18',
    ISO3136: 'PF',
  },
  {
    LAT: -43,
    LONG: 67,
    DMS_LAT: -430000,
    DMS_LONG: 670000,
    MGRS: '42GUT3698137244',
    JOG: 'SK42-07',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'FS',
    SHORT_NAME: 'French Southern and Antarctic Lands',
    FULL_NAME: 'French Southern and Antarctic Lands',
    MOD_DATE: '2010-11-18',
    ISO3136: 'TF',
  },
  {
    LAT: -1,
    LONG: 11.75,
    DMS_LAT: -10000,
    DMS_LONG: 114500,
    MGRS: '32MRD0607889342',
    JOG: 'SA32-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GB',
    SHORT_NAME: 'Gabon',
    FULL_NAME: 'Gabonese Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GA',
  },
  {
    LAT: 13.5,
    LONG: -15.5,
    DMS_LAT: 133000,
    DMS_LONG: -153000,
    MGRS: '28PDV4588992485',
    JOG: 'ND28-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GA',
    SHORT_NAME: 'Gambia',
    FULL_NAME: 'Republic of The Gambia',
    MOD_DATE: '2011-11-29',
    ISO3136: 'GM',
  },
  {
    LAT: 31.425074,
    LONG: 34.373398,
    DMS_LAT: 312530,
    DMS_LONG: 342224,
    MGRS: '36RXV3053377528',
    JOG: 'NH36-03',
    DSG: 'TERR',
    AFFIL: '',
    FIPS10: 'GZ',
    SHORT_NAME: 'Gaza Strip',
    FULL_NAME: 'Gaza Strip',
    MOD_DATE: '2009-01-23',
    ISO3136: 'PS',
  },
  {
    LAT: 41.999981,
    LONG: 43.499905,
    DMS_LAT: 420000,
    DMS_LONG: 433000,
    MGRS: '38TLM7576450862',
    JOG: 'NK38-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GG',
    SHORT_NAME: 'Georgia',
    FULL_NAME: 'Georgia',
    MOD_DATE: '2010-08-02',
    ISO3136: 'GE',
  },
  {
    LAT: 51.5,
    LONG: 10.5,
    DMS_LAT: 513000,
    DMS_LONG: 103000,
    MGRS: '32UPC0411606496',
    JOG: 'NM32-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GM',
    SHORT_NAME: 'Germany',
    FULL_NAME: 'Federal Republic of Germany',
    MOD_DATE: '2007-02-28',
    ISO3136: 'DE',
  },
  {
    LAT: 8,
    LONG: -2,
    DMS_LAT: 80000,
    DMS_LONG: -20000,
    MGRS: '30PXP1020584432',
    JOG: 'NC30-15',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GH',
    SHORT_NAME: 'Ghana',
    FULL_NAME: 'Republic of Ghana',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GH',
  },
  {
    LAT: 36.133333,
    LONG: -5.35,
    DMS_LAT: 360800,
    DMS_LONG: -52100,
    MGRS: '30STF8853901295',
    JOG: 'NJ30-10',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'GI',
    SHORT_NAME: 'Gibraltar',
    FULL_NAME: 'Gibraltar',
    MOD_DATE: '1993-12-22',
    ISO3136: 'GI',
  },
  {
    LAT: -11.5,
    LONG: 47.333333,
    DMS_LAT: -113000,
    DMS_LONG: 472000,
    MGRS: '38LQN5452827701',
    JOG: 'SC38-16',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'GO',
    SHORT_NAME: 'Glorioso Islands',
    FULL_NAME: 'Glorioso Islands',
    MOD_DATE: '1997-07-10',
    ISO3136: '',
  },
  {
    LAT: 39,
    LONG: 22,
    DMS_LAT: 390000,
    DMS_LONG: 220000,
    MGRS: '34SEJ8659317252',
    JOG: 'NJ34-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GR',
    SHORT_NAME: 'Greece',
    FULL_NAME: 'Hellenic Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GR',
  },
  {
    LAT: 72,
    LONG: -40,
    DMS_LAT: 720000,
    DMS_LONG: -400000,
    MGRS: '24XVE6551189219',
    JOG: 'NS23-12',
    DSG: 'PCLS',
    AFFIL: 'DA',
    FIPS10: 'GL',
    SHORT_NAME: 'Greenland',
    FULL_NAME: 'Greenland',
    MOD_DATE: '2010-04-16',
    ISO3136: 'GL',
  },
  {
    LAT: 12.116667,
    LONG: -61.666667,
    DMS_LAT: 120700,
    DMS_LONG: -614000,
    MGRS: '20PPU4509539809',
    JOG: 'ND20-16',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GJ',
    SHORT_NAME: 'Grenada',
    FULL_NAME: 'Grenada',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GD',
  },
  {
    LAT: 16.25,
    LONG: -61.583333,
    DMS_LAT: 161500,
    DMS_LONG: -613500,
    MGRS: '20QPC5139597113',
    JOG: 'NE20-15',
    DSG: 'ADM1',
    AFFIL: 'FR',
    FIPS10: 'GP',
    SHORT_NAME: 'Guadeloupe',
    FULL_NAME: 'Department of Guadeloupe',
    MOD_DATE: '2007/02/28',
    ISO3136: 'GP',
  },
  {
    LAT: 13.4444444,
    LONG: 144.7366667,
    DMS_LAT: 132640,
    DMS_LONG: 1444412,
    MGRS: 1802705,
    JOG: '',
    DSG: 'PCLD',
    AFFIL: 'US',
    FIPS10: 'GU',
    SHORT_NAME: 'Guam',
    FULL_NAME: 'Territory of Guam',
    MOD_DATE: '1998-10-06',
    ISO3136: 'GU',
  },
  {
    LAT: 15.5,
    LONG: -90.25,
    DMS_LAT: 153000,
    DMS_LONG: -901500,
    MGRS: '15PYT9504515523',
    JOG: 'ND15-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GT',
    SHORT_NAME: 'Guatemala',
    FULL_NAME: 'Republic of Guatemala',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GT',
  },
  {
    LAT: 49.583333,
    LONG: -2.333333,
    DMS_LAT: 493500,
    DMS_LONG: -22000,
    MGRS: '30UWV4819092519',
    JOG: 'NM30-08',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'GK',
    SHORT_NAME: 'Guernsey',
    FULL_NAME: 'Bailiwick of Guernsey',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GG',
  },
  {
    LAT: 11,
    LONG: -10,
    DMS_LAT: 110000,
    DMS_LONG: -100000,
    MGRS: '29PLN9075116161',
    JOG: 'NC29-02',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GV',
    SHORT_NAME: 'Guinea',
    FULL_NAME: 'Republic of Guinea',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GN',
  },
  {
    LAT: 12,
    LONG: -15,
    DMS_LAT: 120000,
    DMS_LONG: -150000,
    MGRS: '28PEU0000026554',
    JOG: 'ND28-15',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'PU',
    SHORT_NAME: 'Guinea-Bissau',
    FULL_NAME: 'Republic of Guinea-Bissau',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GW',
  },
  {
    LAT: 5,
    LONG: -59,
    DMS_LAT: 50000,
    DMS_LONG: -590000,
    MGRS: '21NTF7824753002',
    JOG: 'NB21-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'GY',
    SHORT_NAME: 'Guyana',
    FULL_NAME: 'Co-operative Republic of Guyana',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GY',
  },
  {
    LAT: 19,
    LONG: -72.416667,
    DMS_LAT: 190000,
    DMS_LONG: -722500,
    MGRS: '18QYG7196902825',
    JOG: 'NE18-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'HA',
    SHORT_NAME: 'Haiti',
    FULL_NAME: 'Republic of Haiti',
    MOD_DATE: '2007-02-28',
    ISO3136: 'HT',
  },
  {
    LAT: -53,
    LONG: 73,
    DMS_LAT: -530000,
    DMS_LONG: 730000,
    MGRS: '43FCB6578725858',
    JOG: 'SN43-01',
    DSG: 'PCLD',
    AFFIL: 'AS',
    FIPS10: 'HM',
    SHORT_NAME: 'Heard Island and McDonald Islands',
    FULL_NAME: 'Territory of Heard Island and McDonald Islands',
    MOD_DATE: '2007-02-28',
    ISO3136: 'HM',
  },
  {
    LAT: 15,
    LONG: -86.5,
    DMS_LAT: 150000,
    DMS_LONG: -863000,
    MGRS: '16PEB5375458387',
    JOG: 'ND16-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'HO',
    SHORT_NAME: 'Honduras',
    FULL_NAME: 'Republic of Honduras',
    MOD_DATE: '2007-02-28',
    ISO3136: 'HN',
  },
  {
    LAT: 22.25,
    LONG: 114.166667,
    DMS_LAT: 221500,
    DMS_LONG: 1141000,
    MGRS: '50QKK0797163234',
    JOG: 'NF50-05',
    DSG: 'PCLS',
    AFFIL: 'CH',
    FIPS10: 'HK',
    SHORT_NAME: 'Hong Kong',
    FULL_NAME: 'Hong Kong Special Administrative Region',
    MOD_DATE: '2003-04-01',
    ISO3136: 'HK',
  },
  {
    LAT: 47,
    LONG: 20,
    DMS_LAT: 470000,
    DMS_LONG: 200000,
    MGRS: '34TDT2397505649',
    JOG: 'NL34-02',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'HU',
    SHORT_NAME: 'Hungary',
    FULL_NAME: 'Republic of Hungary',
    MOD_DATE: '2007-02-28',
    ISO3136: 'HU',
  },
  {
    LAT: 65,
    LONG: -18,
    DMS_LAT: 650000,
    DMS_LONG: -180000,
    MGRS: '28WCT5857211811',
    JOG: 'NQ27-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'IC',
    SHORT_NAME: 'Iceland',
    FULL_NAME: 'Republic of Iceland',
    MOD_DATE: '2011-02-10',
    ISO3136: 'IS',
  },
  {
    LAT: 20,
    LONG: 77,
    DMS_LAT: 200000,
    DMS_LONG: 770000,
    MGRS: '43QGC0924312731',
    JOG: 'NF43-16',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'IN',
    SHORT_NAME: 'India',
    FULL_NAME: 'Republic of India',
    MOD_DATE: '2007-02-28',
    ISO3136: 'IN',
  },
  {
    LAT: -5,
    LONG: 120,
    DMS_LAT: -50000,
    DMS_LONG: 1200000,
    MGRS: '51MSQ6728646576',
    JOG: 'SB51-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'ID',
    SHORT_NAME: 'Indonesia',
    FULL_NAME: 'Republic of Indonesia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ID',
  },
  {
    LAT: 32,
    LONG: 53,
    DMS_LAT: 320000,
    DMS_LONG: 530000,
    MGRS: '39SXR8892842183',
    JOG: 'NI39-16',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'IR',
    SHORT_NAME: 'Iran',
    FULL_NAME: 'Islamic Republic of Iran',
    MOD_DATE: '2007-02-28',
    ISO3136: 'IR',
  },
  {
    LAT: 33,
    LONG: 44,
    DMS_LAT: 330000,
    DMS_LONG: 440000,
    MGRS: '38SMB0658251731',
    JOG: 'NI38-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'IZ',
    SHORT_NAME: 'Iraq',
    FULL_NAME: 'Republic of Iraq',
    MOD_DATE: '2007-02-28',
    ISO3136: 'IQ',
  },
  {
    LAT: 53,
    LONG: -8,
    DMS_LAT: 530000,
    DMS_LONG: -80000,
    MGRS: '29UNU6710972738',
    JOG: 'NN29-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'EI',
    SHORT_NAME: 'Ireland',
    FULL_NAME: 'Ireland',
    MOD_DATE: '2010-10-15',
    ISO3136: 'IE',
  },
  {
    LAT: 54.25,
    LONG: -4.5,
    DMS_LAT: 541500,
    DMS_LONG: -43000,
    MGRS: '30UVF0226912376',
    JOG: 'NN30-04',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'IM',
    SHORT_NAME: 'Isle of Man',
    FULL_NAME: 'Isle of Man',
    MOD_DATE: '1993-12-22',
    ISO3136: 'IM',
  },
  {
    LAT: 31.5,
    LONG: 34.75,
    DMS_LAT: 313000,
    DMS_LONG: 344500,
    MGRS: '36RXV6619986343',
    JOG: 'NH36-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'IS',
    SHORT_NAME: 'Israel',
    FULL_NAME: 'State of Israel',
    MOD_DATE: '2007-02-28',
    ISO3136: 'IL',
  },
  {
    LAT: 42.833333,
    LONG: 12.833333,
    DMS_LAT: 425000,
    DMS_LONG: 125000,
    MGRS: '33TUH2291944584',
    JOG: 'NK33-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'IT',
    SHORT_NAME: 'Italy',
    FULL_NAME: 'Italian Republic',
    MOD_DATE: '2011-11-29',
    ISO3136: 'IT',
  },
  {
    LAT: 18.25,
    LONG: -77.5,
    DMS_LAT: 181500,
    DMS_LONG: -773000,
    MGRS: '18QTF3565119652',
    JOG: 'NE18-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'JM',
    SHORT_NAME: 'Jamaica',
    FULL_NAME: 'Jamaica',
    MOD_DATE: '1997-06-26',
    ISO3136: 'JM',
  },
  {
    LAT: 36,
    LONG: 138,
    DMS_LAT: 360000,
    DMS_LONG: 1380000,
    MGRS: '54STE2957988112',
    JOG: 'NJ54-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'JA',
    SHORT_NAME: 'Japan',
    FULL_NAME: 'Japan',
    MOD_DATE: '1997-08-29',
    ISO3136: 'JP',
  },
  {
    LAT: 49.216667,
    LONG: -2.116667,
    DMS_LAT: 491300,
    DMS_LONG: -20700,
    MGRS: '30UWV6432851918',
    JOG: 'NM30-08',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'JE',
    SHORT_NAME: 'Jersey',
    FULL_NAME: 'Bailiwick of Jersey',
    MOD_DATE: '2007-02-28',
    ISO3136: 'JE',
  },
  {
    LAT: 31,
    LONG: 36,
    DMS_LAT: 310000,
    DMS_LONG: 360000,
    MGRS: '37RBQ1354033467',
    JOG: 'NH37-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'JO',
    SHORT_NAME: 'Jordan',
    FULL_NAME: 'Hashemite Kingdom of Jordan',
    MOD_DATE: '2007-02-28',
    ISO3136: 'JO',
  },
  {
    LAT: -17.05833,
    LONG: 42.71667,
    DMS_LAT: -170330,
    DMS_LONG: 424300,
    MGRS: '38KKG5697712571',
    JOG: 'SE38-05',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'JU',
    SHORT_NAME: 'Juan de Nova Island',
    FULL_NAME: 'Juan de Nova Island',
    MOD_DATE: '2010-09-30',
    ISO3136: '',
  },
  {
    LAT: 48,
    LONG: 68,
    DMS_LAT: 480000,
    DMS_LONG: 680000,
    MGRS: '42UVU2540516784',
    JOG: 'NM42-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'KZ',
    SHORT_NAME: 'Kazakhstan',
    FULL_NAME: 'Republic of Kazakhstan',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KZ',
  },
  {
    LAT: 1,
    LONG: 38,
    DMS_LAT: 10000,
    DMS_LONG: 380000,
    MGRS: '37NCB8873610547',
    JOG: 'NA37-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'KE',
    SHORT_NAME: 'Kenya',
    FULL_NAME: 'Republic of Kenya',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KE',
  },
  {
    LAT: -5,
    LONG: -170,
    DMS_LAT: -50000,
    DMS_LONG: -1700000,
    MGRS: '02MPV1086047251',
    JOG: 'SB02-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'KR',
    SHORT_NAME: 'Kiribati',
    FULL_NAME: 'Republic of Kiribati',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KI',
  },
  {
    LAT: 42.583333,
    LONG: 21,
    DMS_LAT: 423500,
    DMS_LONG: 210000,
    MGRS: '34TEN0000014546',
    JOG: 'NK34-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'KV',
    SHORT_NAME: 'Kosovo',
    FULL_NAME: 'Republic of Kosovo',
    MOD_DATE: '2009-06-12',
    ISO3136: '',
  },
  {
    LAT: 29.5,
    LONG: 47.75,
    DMS_LAT: 293000,
    DMS_LONG: 474500,
    MGRS: '38RQT6660466535',
    JOG: 'NH38-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'KU',
    SHORT_NAME: 'Kuwait',
    FULL_NAME: 'State of Kuwait',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KW',
  },
  {
    LAT: 41,
    LONG: 75,
    DMS_LAT: 410000,
    DMS_LONG: 750000,
    MGRS: '43TEF0000038757',
    JOG: 'NK43-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'KG',
    SHORT_NAME: 'Kyrgyzstan',
    FULL_NAME: 'Kyrgyz Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KG',
  },
  {
    LAT: 18,
    LONG: 105,
    DMS_LAT: 180000,
    DMS_LONG: 1050000,
    MGRS: '48QWE0000090186',
    JOG: 'NE48-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LA',
    SHORT_NAME: 'Laos',
    FULL_NAME: "Lao People's Democratic Republic",
    MOD_DATE: '2010-12-22',
    ISO3136: 'LA',
  },
  {
    LAT: 57,
    LONG: 25,
    DMS_LAT: 570000,
    DMS_LONG: 250000,
    MGRS: '35VLD7851419164',
    JOG: 'NO35-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LG',
    SHORT_NAME: 'Latvia',
    FULL_NAME: 'Republic of Latvia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LV',
  },
  {
    LAT: 33.833333,
    LONG: 35.833333,
    DMS_LAT: 335000,
    DMS_LONG: 355000,
    MGRS: '36SYC6220347288',
    JOG: 'NI36-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LE',
    SHORT_NAME: 'Lebanon',
    FULL_NAME: 'Republic of Lebanon',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LB',
  },
  {
    LAT: -29.5,
    LONG: 28.25,
    DMS_LAT: -293000,
    DMS_LONG: 281500,
    MGRS: '35JPH2116435966',
    JOG: 'SH35-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LT',
    SHORT_NAME: 'Lesotho',
    FULL_NAME: 'Kingdom of Lesotho',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LS',
  },
  {
    LAT: 6.5,
    LONG: -9.5,
    DMS_LAT: 63000,
    DMS_LONG: -93000,
    MGRS: '29NMH4471718503',
    JOG: 'NB29-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LI',
    SHORT_NAME: 'Liberia',
    FULL_NAME: 'Republic of Liberia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LR',
  },
  {
    LAT: 25,
    LONG: 17,
    DMS_LAT: 250000,
    DMS_LONG: 170000,
    MGRS: '33RYH0184666437',
    JOG: 'NG33-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LY',
    SHORT_NAME: 'Libya',
    FULL_NAME: 'Libya',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LY',
  },
  {
    LAT: 47.166667,
    LONG: 9.533333,
    DMS_LAT: 471000,
    DMS_LONG: 93200,
    MGRS: '32TNT4042123823',
    JOG: 'NL32-02',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LS',
    SHORT_NAME: 'Liechtenstein',
    FULL_NAME: 'Principality of Liechtenstein',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LI',
  },
  {
    LAT: 56,
    LONG: 24,
    DMS_LAT: 560000,
    DMS_LONG: 240000,
    MGRS: '35VLC1292910141',
    JOG: 'NO35-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LH',
    SHORT_NAME: 'Lithuania',
    FULL_NAME: 'Republic of Lithuania',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LT',
  },
  {
    LAT: 49.75,
    LONG: 6.166667,
    DMS_LAT: 494500,
    DMS_LONG: 61000,
    MGRS: '32UKA9590614688',
    JOG: 'NM32-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LU',
    SHORT_NAME: 'Luxembourg',
    FULL_NAME: 'Grand Duchy of Luxembourg',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LU',
  },
  {
    LAT: 22.157778,
    LONG: 113.559722,
    DMS_LAT: 220928,
    DMS_LONG: 1133335,
    MGRS: '49QGE6398752516',
    JOG: 'NF49-08',
    DSG: 'PCLS',
    AFFIL: 'CH',
    FIPS10: 'MC',
    SHORT_NAME: 'Macau',
    FULL_NAME: 'Macau Special Administrative Region',
    MOD_DATE: '2007-05-15',
    ISO3136: 'MO',
  },
  {
    LAT: 41.833333,
    LONG: 22,
    DMS_LAT: 415000,
    DMS_LONG: 220000,
    MGRS: '34TEM8303431755',
    JOG: 'NK34-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MK',
    SHORT_NAME: 'Macedonia',
    FULL_NAME: 'Republic of Macedonia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MK',
  },
  {
    LAT: -20,
    LONG: 47,
    DMS_LAT: -200000,
    DMS_LONG: 470000,
    MGRS: '38KQC0924387269',
    JOG: 'SE38-16',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MA',
    SHORT_NAME: 'Madagascar',
    FULL_NAME: 'Republic of Madagascar',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MG',
  },
  {
    LAT: -13.5,
    LONG: 34,
    DMS_LAT: -133000,
    DMS_LONG: 340000,
    MGRS: '36LXL0822507350',
    JOG: 'SD36-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MI',
    SHORT_NAME: 'Malawi',
    FULL_NAME: 'Republic of Malawi',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MW',
  },
  {
    LAT: 2.5,
    LONG: 112.5,
    DMS_LAT: 23000,
    DMS_LONG: 1123000,
    MGRS: '49NFC6677476422',
    JOG: 'NA49-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MY',
    SHORT_NAME: 'Malaysia',
    FULL_NAME: 'Malaysia',
    MOD_DATE: '2003-11-25',
    ISO3136: 'MY',
  },
  {
    LAT: 3.2,
    LONG: 73,
    DMS_LAT: 31200,
    DMS_LONG: 730000,
    MGRS: '43NBD7775053916',
    JOG: 'NA43-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MV',
    SHORT_NAME: 'Maldives',
    FULL_NAME: 'Republic of Maldives',
    MOD_DATE: '1997-08-11',
    ISO3136: 'MV',
  },
  {
    LAT: 17,
    LONG: -4,
    DMS_LAT: 170000,
    DMS_LONG: -40000,
    MGRS: '30QUD9355279827',
    JOG: 'NE30-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'ML',
    SHORT_NAME: 'Mali',
    FULL_NAME: 'Republic of Mali',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ML',
  },
  {
    LAT: 35.916667,
    LONG: 14.433333,
    DMS_LAT: 355500,
    DMS_LONG: 142600,
    MGRS: '33SVV4887474854',
    JOG: 'NI33-02',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MT',
    SHORT_NAME: 'Malta',
    FULL_NAME: 'Republic of Malta',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MT',
  },
  {
    LAT: 10,
    LONG: 167,
    DMS_LAT: 100000,
    DMS_LONG: 1670000,
    MGRS: '58PGS1923306077',
    JOG: 'NC58-08',
    DSG: 'PCLF',
    AFFIL: '',
    FIPS10: 'RM',
    SHORT_NAME: 'Marshall Islands',
    FULL_NAME: 'Republic of the Marshall Islands',
    MOD_DATE: '1996-12-16',
    ISO3136: 'MH',
  },
  {
    LAT: 14.666667,
    LONG: -61,
    DMS_LAT: 144000,
    DMS_LONG: -610000,
    MGRS: '20PQB1538322410',
    JOG: 'ND20-08',
    DSG: 'ADM1',
    AFFIL: 'FR',
    FIPS10: 'MB',
    SHORT_NAME: 'Martinique',
    FULL_NAME: 'Department of Martinique',
    MOD_DATE: '2007/02/28',
    ISO3136: 'MQ',
  },
  {
    LAT: 20,
    LONG: -12,
    DMS_LAT: 200000,
    DMS_LONG: -120000,
    MGRS: '29QJC8607414294',
    JOG: 'NF29-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MR',
    SHORT_NAME: 'Mauritania',
    FULL_NAME: 'Islamic Republic of Mauritania',
    MOD_DATE: '2007-05-23',
    ISO3136: 'MR',
  },
  {
    LAT: -20.3,
    LONG: 57.583333,
    DMS_LAT: -201800,
    DMS_LONG: 573500,
    MGRS: '40KEC6090455213',
    JOG: 'SF40-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MP',
    SHORT_NAME: 'Mauritius',
    FULL_NAME: 'Republic of Mauritius',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MU',
  },
  {
    LAT: -12.833333,
    LONG: 45.166667,
    DMS_LAT: -125000,
    DMS_LONG: 451000,
    MGRS: '38LNL1808681289',
    JOG: 'SD38-02',
    DSG: 'ADM1',
    AFFIL: 'FR',
    FIPS10: 'MF',
    SHORT_NAME: 'Mayotte',
    FULL_NAME: 'Department of Mayotte',
    MOD_DATE: '2012/01/18',
    ISO3136: 'YT',
  },
  {
    LAT: 23,
    LONG: -102,
    DMS_LAT: 230000,
    DMS_LONG: -1020000,
    MGRS: '14QJL9245746668',
    JOG: 'NF14-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MX',
    SHORT_NAME: 'Mexico',
    FULL_NAME: 'United Mexican States',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MX',
  },
  {
    LAT: 47,
    LONG: 29,
    DMS_LAT: 470000,
    DMS_LONG: 290000,
    MGRS: '35TPN5204907105',
    JOG: 'NL35-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MD',
    SHORT_NAME: 'Moldova',
    FULL_NAME: 'Republic of Moldova',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MD',
  },
  {
    LAT: 43.733333,
    LONG: 7.4,
    DMS_LAT: 434400,
    DMS_LONG: 72400,
    MGRS: '32TLP7114843499',
    JOG: 'NK32-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MN',
    SHORT_NAME: 'Monaco',
    FULL_NAME: 'Principality of Monaco',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MC',
  },
  {
    LAT: 46,
    LONG: 105,
    DMS_LAT: 460000,
    DMS_LONG: 1050000,
    MGRS: '48TWR0000094047',
    JOG: 'NL48-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MG',
    SHORT_NAME: 'Mongolia',
    FULL_NAME: 'Mongolia',
    MOD_DATE: '2006-02-13',
    ISO3136: 'MN',
  },
  {
    LAT: 42.5,
    LONG: 19.3,
    DMS_LAT: 423000,
    DMS_LONG: 191800,
    MGRS: '34TCN6031606693',
    JOG: 'NK34-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MJ',
    SHORT_NAME: 'Montenegro',
    FULL_NAME: 'Montenegro',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ME',
  },
  {
    LAT: 16.75,
    LONG: -62.2,
    DMS_LAT: 164500,
    DMS_LONG: -621200,
    MGRS: '20QND8526952071',
    JOG: 'NE20-15',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'MH',
    SHORT_NAME: 'Montserrat',
    FULL_NAME: 'Montserrat',
    MOD_DATE: '1993-12-22',
    ISO3136: 'MS',
  },
  {
    LAT: 32,
    LONG: -5,
    DMS_LAT: 320000,
    DMS_LONG: -50000,
    MGRS: '30SUA1107242183',
    JOG: 'NI30-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MO',
    SHORT_NAME: 'Morocco',
    FULL_NAME: 'Kingdom of Morocco',
    MOD_DATE: '2007-05-23',
    ISO3136: 'MA',
  },
  {
    LAT: -18.25,
    LONG: 35,
    DMS_LAT: -181500,
    DMS_LONG: 350000,
    MGRS: '36KYE1146080999',
    JOG: 'SE36-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MZ',
    SHORT_NAME: 'Mozambique',
    FULL_NAME: 'Republic of Mozambique',
    MOD_DATE: '2007-02-28',
    ISO3136: 'MZ',
  },
  {
    LAT: -22,
    LONG: 17,
    DMS_LAT: -220000,
    DMS_LONG: 170000,
    MGRS: '33KYR0647265823',
    JOG: 'SF33-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'WA',
    SHORT_NAME: 'Namibia',
    FULL_NAME: 'Republic of Namibia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'NA',
  },
  {
    LAT: -0.533333,
    LONG: 166.916667,
    DMS_LAT: -3200,
    DMS_LONG: 1665500,
    MGRS: '58MGE1330841017',
    JOG: 'SA58-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NR',
    SHORT_NAME: 'Nauru',
    FULL_NAME: 'Republic of Nauru',
    MOD_DATE: '1996-11-29',
    ISO3136: 'NR',
  },
  {
    LAT: 28,
    LONG: 84,
    DMS_LAT: 280000,
    DMS_LONG: 840000,
    MGRS: '45RTM0495700831',
    JOG: 'NH45-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NP',
    SHORT_NAME: 'Nepal',
    FULL_NAME: 'Federal Democratic Republic of Nepal',
    MOD_DATE: '2007-04-13',
    ISO3136: 'NP',
  },
  {
    LAT: 52.5,
    LONG: 5.75,
    DMS_LAT: 523000,
    DMS_LONG: 54500,
    MGRS: '31UFU8666020207',
    JOG: 'NN31-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NL',
    SHORT_NAME: 'Netherlands',
    FULL_NAME: 'Kingdom of the Netherlands',
    MOD_DATE: '2007-02-28',
    ISO3136: 'NL',
  },
  {
    LAT: -21.5,
    LONG: 165.5,
    DMS_LAT: -213000,
    DMS_LONG: 1653000,
    MGRS: '58KEB5179022432',
    JOG: 'SF58-07',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'NC',
    SHORT_NAME: 'New Caledonia',
    FULL_NAME: 'New Caledonia',
    MOD_DATE: '2010-11-18',
    ISO3136: 'NC',
  },
  {
    LAT: -42,
    LONG: 174,
    DMS_LAT: -420000,
    DMS_LONG: 1740000,
    MGRS: '60GTU5153545869',
    JOG: 'SK59-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NZ',
    SHORT_NAME: 'New Zealand',
    FULL_NAME: 'New Zealand',
    MOD_DATE: '1993-12-30',
    ISO3136: 'NZ',
  },
  {
    LAT: 13,
    LONG: -85,
    DMS_LAT: 130000,
    DMS_LONG: -850000,
    MGRS: '16PGV1692337988',
    JOG: 'ND16-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NU',
    SHORT_NAME: 'Nicaragua',
    FULL_NAME: 'Republic of Nicaragua',
    MOD_DATE: '2007-02-28',
    ISO3136: 'NI',
  },
  {
    LAT: 16,
    LONG: 8,
    DMS_LAT: 160000,
    DMS_LONG: 80000,
    MGRS: '32QLC9300469193',
    JOG: 'NE32-14',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NG',
    SHORT_NAME: 'Niger',
    FULL_NAME: 'Republic of Niger',
    MOD_DATE: '2007-02-28',
    ISO3136: 'NE',
  },
  {
    LAT: 10,
    LONG: 8,
    DMS_LAT: 100000,
    DMS_LONG: 80000,
    MGRS: '32PLS9039905579',
    JOG: 'NC32-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NI',
    SHORT_NAME: 'Nigeria',
    FULL_NAME: 'Federal Republic of Nigeria',
    MOD_DATE: '2007-02-28',
    ISO3136: 'NG',
  },
  {
    LAT: -19.033333,
    LONG: -169.866667,
    DMS_LAT: -190200,
    DMS_LONG: -1695200,
    MGRS: '02KPD1926695100',
    JOG: 'SE02-15',
    DSG: 'PCLS',
    AFFIL: 'NZ',
    FIPS10: 'NE',
    SHORT_NAME: 'Niue',
    FULL_NAME: 'Niue',
    MOD_DATE: '1996-11-29',
    ISO3136: 'NU',
  },
  {
    LAT: -29.033333,
    LONG: 167.95,
    DMS_LAT: -290200,
    DMS_LONG: 1675700,
    MGRS: '58JGN8730584730',
    JOG: 'SH58-08',
    DSG: 'PCLD',
    AFFIL: 'AS',
    FIPS10: 'NF',
    SHORT_NAME: 'Norfolk Island',
    FULL_NAME: 'Territory of Norfolk Island',
    MOD_DATE: '2007-02-28',
    ISO3136: 'NF',
  },
  {
    LAT: 40,
    LONG: 127,
    DMS_LAT: 400000,
    DMS_LONG: 1270000,
    MGRS: '52TCK2927529673',
    JOG: 'NK52-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'KN',
    SHORT_NAME: 'North Korea',
    FULL_NAME: "Democratic People's Republic of Korea",
    MOD_DATE: '2007-02-28',
    ISO3136: 'KP',
  },
  {
    LAT: 16,
    LONG: 146,
    DMS_LAT: 160000,
    DMS_LONG: 1460000,
    MGRS: 1779809,
    JOG: '',
    DSG: 'PCLD',
    AFFIL: 'US',
    FIPS10: 'MP',
    SHORT_NAME: 'Northern Mariana Islands',
    FULL_NAME: 'Commonwealth of the Northern Mariana Islands',
    MOD_DATE: '1998-02-06',
    ISO3136: 'MP',
  },
  {
    LAT: 62,
    LONG: 10,
    DMS_LAT: 620000,
    DMS_LONG: 100000,
    MGRS: '32VNP5237674584',
    JOG: 'NP31-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NO',
    SHORT_NAME: 'Norway',
    FULL_NAME: 'Kingdom of Norway',
    MOD_DATE: '2007-02-28',
    ISO3136: 'NO',
  },
  {
    LAT: 21,
    LONG: 57,
    DMS_LAT: 210000,
    DMS_LONG: 570000,
    MGRS: '40QEJ0000022148',
    JOG: 'NF40-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'MU',
    SHORT_NAME: 'Oman',
    FULL_NAME: 'Sultanate of Oman',
    MOD_DATE: '2010-12-15',
    ISO3136: 'OM',
  },
  {
    LAT: 30,
    LONG: 70,
    DMS_LAT: 300000,
    DMS_LONG: 700000,
    MGRS: '42RWU9645019206',
    JOG: 'NH42-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'PK',
    SHORT_NAME: 'Pakistan',
    FULL_NAME: 'Islamic Republic of Pakistan',
    MOD_DATE: '1997-11-18',
    ISO3136: 'PK',
  },
  {
    LAT: 6,
    LONG: 134,
    DMS_LAT: 60000,
    DMS_LONG: 1340000,
    MGRS: '53NLG8932563306',
    JOG: 'NB53-06',
    DSG: 'PCLF',
    AFFIL: '',
    FIPS10: 'PS',
    SHORT_NAME: 'Palau',
    FULL_NAME: 'Republic of Palau',
    MOD_DATE: '2007-02-28',
    ISO3136: 'PW',
  },
  {
    LAT: 9,
    LONG: -80,
    DMS_LAT: 90000,
    DMS_LONG: -800000,
    MGRS: '17PPK0991995002',
    JOG: 'NC17-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'PM',
    SHORT_NAME: 'Panama',
    FULL_NAME: 'Republic of Panama',
    MOD_DATE: '2007-02-28',
    ISO3136: 'PA',
  },
  {
    LAT: -6,
    LONG: 147,
    DMS_LAT: -60000,
    DMS_LONG: 1470000,
    MGRS: '55MEP0000036795',
    JOG: 'SB55-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'PP',
    SHORT_NAME: 'Papua New Guinea',
    FULL_NAME: 'Independent State of Papua New Guinea',
    MOD_DATE: '2007-02-28',
    ISO3136: 'PG',
  },
  {
    LAT: -22.993333,
    LONG: -57.996389,
    DMS_LAT: -225936,
    DMS_LONG: -575947,
    MGRS: '21KUQ9788056871',
    JOG: 'SF21-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'PA',
    SHORT_NAME: 'Paraguay',
    FULL_NAME: 'Republic of Paraguay',
    MOD_DATE: '2007-02-28',
    ISO3136: 'PY',
  },
  {
    LAT: -10,
    LONG: -76,
    DMS_LAT: -100000,
    DMS_LONG: -760000,
    MGRS: '18LUP9039994421',
    JOG: 'SC18-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'PE',
    SHORT_NAME: 'Peru',
    FULL_NAME: 'Republic of Peru',
    MOD_DATE: '2007-02-28',
    ISO3136: 'PE',
  },
  {
    LAT: 13,
    LONG: 122,
    DMS_LAT: 130000,
    DMS_LONG: 1220000,
    MGRS: '51PUQ9155437349',
    JOG: 'ND51-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'RP',
    SHORT_NAME: 'Philippines',
    FULL_NAME: 'Republic of the Philippines',
    MOD_DATE: '2007-02-28',
    ISO3136: 'PH',
  },
  {
    LAT: -25.066667,
    LONG: -130.1,
    DMS_LAT: -250400,
    DMS_LONG: -1300600,
    MGRS: '09JUN8905527219',
    JOG: 'SG09-06',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'PC',
    SHORT_NAME: 'Pitcairn Islands',
    FULL_NAME: 'Henderson Ducie and Oeno Islands Pitcairn',
    MOD_DATE: '2007-02-28',
    ISO3136: 'PN',
  },
  {
    LAT: 52,
    LONG: 20,
    DMS_LAT: 520000,
    DMS_LONG: 200000,
    MGRS: '34UDC3135061510',
    JOG: 'NN34-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'PL',
    SHORT_NAME: 'Poland',
    FULL_NAME: 'Republic of Poland',
    MOD_DATE: '2007-02-28',
    ISO3136: 'PL',
  },
  {
    LAT: 39.5,
    LONG: -8,
    DMS_LAT: 393000,
    DMS_LONG: -80000,
    MGRS: '29SND8598072742',
    JOG: 'NJ29-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'PO',
    SHORT_NAME: 'Portugal',
    FULL_NAME: 'Portuguese Republic',
    MOD_DATE: '2007-03-09',
    ISO3136: 'PT',
  },
  {
    LAT: 18.2482882,
    LONG: -66.4998941,
    DMS_LAT: 181454,
    DMS_LONG: -663000,
    MGRS: 1779808,
    JOG: '',
    DSG: 'PCLD',
    AFFIL: 'US',
    FIPS10: 'PR',
    SHORT_NAME: 'Puerto Rico',
    FULL_NAME: 'Commonwealth of Puerto Rico',
    MOD_DATE: '1998-02-06',
    ISO3136: 'PR',
  },
  {
    LAT: 25.5,
    LONG: 51.25,
    DMS_LAT: 253000,
    DMS_LONG: 511500,
    MGRS: '39RWJ2512420338',
    JOG: 'NG39-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'QA',
    SHORT_NAME: 'Qatar',
    FULL_NAME: 'State of Qatar',
    MOD_DATE: '2011-01-28',
    ISO3136: 'QA',
  },
  {
    LAT: -1,
    LONG: 15,
    DMS_LAT: -10000,
    DMS_LONG: 150000,
    MGRS: '33MWU0000089470',
    JOG: 'SA33-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CF',
    SHORT_NAME: 'Republic of the Congo',
    FULL_NAME: 'Republic of the Congo',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CG',
  },
  {
    LAT: -21.1,
    LONG: 55.6,
    DMS_LAT: -210600,
    DMS_LONG: 553600,
    MGRS: '40KCB5458666145',
    JOG: 'SF40-06',
    DSG: 'ADM1',
    AFFIL: 'FR',
    FIPS10: 'RE',
    SHORT_NAME: 'Reunion',
    FULL_NAME: 'Department of Reunion',
    MOD_DATE: '2007/02/28',
    ISO3136: 'RE',
  },
  {
    LAT: 46,
    LONG: 25,
    DMS_LAT: 460000,
    DMS_LONG: 250000,
    MGRS: '35TLL4513695992',
    JOG: 'NL35-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'RO',
    SHORT_NAME: 'Romania',
    FULL_NAME: 'Romania',
    MOD_DATE: '2007-02-28',
    ISO3136: 'RO',
  },
  {
    LAT: 60,
    LONG: 100,
    DMS_LAT: 600000,
    DMS_LONG: 1000000,
    MGRS: '47VNG5577651833',
    JOG: 'NP47-14',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'RS',
    SHORT_NAME: 'Russia',
    FULL_NAME: 'Russian Federation',
    MOD_DATE: '2007-02-28',
    ISO3136: 'RU',
  },
  {
    LAT: -2,
    LONG: 30,
    DMS_LAT: -20000,
    DMS_LONG: 300000,
    MGRS: '36MSC6622478634',
    JOG: 'SA36-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'RW',
    SHORT_NAME: 'Rwanda',
    FULL_NAME: 'Republic of Rwanda',
    MOD_DATE: '2007-03-09',
    ISO3136: 'RW',
  },
  {
    LAT: 17.9,
    LONG: -62.833333,
    DMS_LAT: 175400,
    DMS_LONG: -625000,
    MGRS: '20QNE1765479130',
    JOG: 'NE20-11',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'TB',
    SHORT_NAME: 'Saint Barthelemy',
    FULL_NAME: 'Saint Barthelemy',
    MOD_DATE: '2010-11-04',
    ISO3136: 'BL',
  },
  {
    LAT: 17.333333,
    LONG: -62.75,
    DMS_LAT: 172000,
    DMS_LONG: -624500,
    MGRS: '20QNE2656316448',
    JOG: 'NE20-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SC',
    SHORT_NAME: 'Saint Kitts and Nevis',
    FULL_NAME: 'Federation of Saint Kitts and Nevis',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KN',
  },
  {
    LAT: 13.883333,
    LONG: -60.966667,
    DMS_LAT: 135300,
    DMS_LONG: -605800,
    MGRS: '20PQA1973335759',
    JOG: 'ND20-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'ST',
    SHORT_NAME: 'Saint Lucia',
    FULL_NAME: 'Saint Lucia',
    MOD_DATE: '1993-12-22',
    ISO3136: 'LC',
  },
  {
    LAT: 18.075,
    LONG: -63.05833,
    DMS_LAT: 180430,
    DMS_LONG: -630330,
    MGRS: '20QME9382898484',
    JOG: 'NE20-06',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'RN',
    SHORT_NAME: 'Saint Martin',
    FULL_NAME: 'Saint Martin',
    MOD_DATE: '2010-11-04',
    ISO3136: 'MF',
  },
  {
    LAT: 46.833333,
    LONG: -56.333333,
    DMS_LAT: 465000,
    DMS_LONG: -562000,
    MGRS: '21TWM5084186859',
    JOG: 'NL21-02',
    DSG: 'ADM1',
    AFFIL: 'FR',
    FIPS10: 'SB',
    SHORT_NAME: 'Saint Pierre and Miquelon',
    FULL_NAME: 'Territorial Collectivity of Saint Pierre and Miquelon',
    MOD_DATE: '2007/02/28',
    ISO3136: 'PM',
  },
  {
    LAT: 13.083333,
    LONG: -61.2,
    DMS_LAT: 130500,
    DMS_LONG: -611200,
    MGRS: '20PPV9515847045',
    JOG: 'ND20-16',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'VC',
    SHORT_NAME: 'Saint Vincent and the Grenadines',
    FULL_NAME: 'Saint Vincent and the Grenadines',
    MOD_DATE: '1993-12-22',
    ISO3136: 'VC',
  },
  {
    LAT: -13.803096,
    LONG: -172.178309,
    DMS_LAT: -134811,
    DMS_LONG: -1721042,
    MGRS: '02LLK7263873738',
    JOG: 'SD02-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'WS',
    SHORT_NAME: 'Samoa',
    FULL_NAME: 'Independent State of Samoa',
    MOD_DATE: '2009-11-03',
    ISO3136: 'WS',
  },
  {
    LAT: 43.933333,
    LONG: 12.416667,
    DMS_LAT: 435600,
    DMS_LONG: 122500,
    MGRS: '33TTJ9264967713',
    JOG: 'NK33-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SM',
    SHORT_NAME: 'San Marino',
    FULL_NAME: 'Republic of San Marino',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SM',
  },
  {
    LAT: 1,
    LONG: 7,
    DMS_LAT: 10000,
    DMS_LONG: 70000,
    MGRS: '32NKG7743810598',
    JOG: 'NA32-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TP',
    SHORT_NAME: 'Sao Tome and Principe',
    FULL_NAME: 'Democratic Republic of Sao Tome and Principe',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ST',
  },
  {
    LAT: 25,
    LONG: 45,
    DMS_LAT: 250000,
    DMS_LONG: 450000,
    MGRS: '38RNN0000064948',
    JOG: 'NG38-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SA',
    SHORT_NAME: 'Saudi Arabia',
    FULL_NAME: 'Kingdom of Saudi Arabia',
    MOD_DATE: '2009-07-28',
    ISO3136: 'SA',
  },
  {
    LAT: 14,
    LONG: -14,
    DMS_LAT: 140000,
    DMS_LONG: -140000,
    MGRS: '28PFA0799647954',
    JOG: 'ND28-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SG',
    SHORT_NAME: 'Senegal',
    FULL_NAME: 'Republic of Senegal',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SN',
  },
  {
    LAT: 44,
    LONG: 21,
    DMS_LAT: 440000,
    DMS_LONG: 210000,
    MGRS: '34TEP0000071873',
    JOG: 'NL34-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'RI',
    SHORT_NAME: 'Serbia',
    FULL_NAME: 'Republic of Serbia',
    MOD_DATE: '2009-02-28',
    ISO3136: 'RS',
  },
  {
    LAT: -4.583333,
    LONG: 55.666667,
    DMS_LAT: -43500,
    DMS_LONG: 554000,
    MGRS: '40MCV5209193256',
    JOG: 'SB40-01',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SE',
    SHORT_NAME: 'Seychelles',
    FULL_NAME: 'Republic of Seychelles',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SC',
  },
  {
    LAT: 8.5,
    LONG: -11.5,
    DMS_LAT: 83000,
    DMS_LONG: -113000,
    MGRS: '29PKK2476440462',
    JOG: 'NC29-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SL',
    SHORT_NAME: 'Sierra Leone',
    FULL_NAME: 'Republic of Sierra Leone',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SL',
  },
  {
    LAT: 1.366667,
    LONG: 103.8,
    DMS_LAT: 12200,
    DMS_LONG: 1034800,
    MGRS: '48NUG6649851091',
    JOG: 'NA48-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SN',
    SHORT_NAME: 'Singapore',
    FULL_NAME: 'Republic of Singapore',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SG',
  },
  {
    LAT: 18.04167,
    LONG: -63.06667,
    DMS_LAT: 180230,
    DMS_LONG: -630400,
    MGRS: '20QME9294494797',
    JOG: 'NE20-06',
    DSG: 'PCLIX',
    AFFIL: 'NL',
    FIPS10: 'NN',
    SHORT_NAME: 'Sint Maarten',
    FULL_NAME: 'Land Sint Maarten',
    MOD_DATE: '2010/11/04',
    ISO3136: 'SX',
  },
  {
    LAT: 48.666667,
    LONG: 19.5,
    DMS_LAT: 484000,
    DMS_LONG: 193000,
    MGRS: '34UCU8955891487',
    JOG: 'NM34-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'LO',
    SHORT_NAME: 'Slovakia',
    FULL_NAME: 'Slovak Republic',
    MOD_DATE: '2011-11-29',
    ISO3136: 'SK',
  },
  {
    LAT: 46.25,
    LONG: 15.166667,
    DMS_LAT: 461500,
    DMS_LONG: 151000,
    MGRS: '33TWM1284721838',
    JOG: 'NL33-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SI',
    SHORT_NAME: 'Slovenia',
    FULL_NAME: 'Republic of Slovenia',
    MOD_DATE: '2011-11-29',
    ISO3136: 'SI',
  },
  {
    LAT: -8,
    LONG: 159,
    DMS_LAT: -80000,
    DMS_LONG: 1590000,
    MGRS: '57MWM0000015702',
    JOG: 'SB57-15',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'BP',
    SHORT_NAME: 'Solomon Islands',
    FULL_NAME: 'Solomon Islands',
    MOD_DATE: '1997-08-29',
    ISO3136: 'SB',
  },
  {
    LAT: 6,
    LONG: 48,
    DMS_LAT: 60000,
    DMS_LONG: 480000,
    MGRS: '39NSG6784264114',
    JOG: 'NB39-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SO',
    SHORT_NAME: 'Somalia',
    FULL_NAME: 'Somalia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SO',
  },
  {
    LAT: -30,
    LONG: 26,
    DMS_LAT: -300000,
    DMS_LONG: 260000,
    MGRS: '35JMG0355080794',
    JOG: 'SH35-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SF',
    SHORT_NAME: 'South Africa',
    FULL_NAME: 'South Africa',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ZA',
  },
  {
    LAT: -56,
    LONG: -33,
    DMS_LAT: -560000,
    DMS_LONG: -330000,
    MGRS: '25FET0000093920',
    JOG: 'SN25-11',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'SX',
    SHORT_NAME: 'South Georgia and South Sandwich Islands',
    FULL_NAME: 'South Georgia and the South Sandwich Islands',
    MOD_DATE: '1998-11-18',
    ISO3136: 'GS',
  },
  {
    LAT: 37,
    LONG: 127.5,
    DMS_LAT: 370000,
    DMS_LONG: 1273000,
    MGRS: '52SCF6653295924',
    JOG: 'NJ52-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'KS',
    SHORT_NAME: 'South Korea',
    FULL_NAME: 'Republic of Korea',
    MOD_DATE: '2007-02-28',
    ISO3136: 'KR',
  },
  {
    LAT: 8,
    LONG: 30,
    DMS_LAT: 80000,
    DMS_LONG: 300000,
    MGRS: '36PSP6925685504',
    JOG: 'NC36-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'OD',
    SHORT_NAME: 'South Sudan',
    FULL_NAME: 'Republic of South Sudan',
    MOD_DATE: '2011-07-12',
    ISO3136: 'SS',
  },
  {
    LAT: 40,
    LONG: -4,
    DMS_LAT: 400000,
    DMS_LONG: -40000,
    MGRS: '30TVK1464028236',
    JOG: 'NK30-11',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SP',
    SHORT_NAME: 'Spain',
    FULL_NAME: 'Kingdom of Spain',
    MOD_DATE: '1997-07-12',
    ISO3136: 'ES',
  },
  {
    LAT: 7,
    LONG: 81,
    DMS_LAT: 70000,
    DMS_LONG: 810000,
    MGRS: '44NNN0000073749',
    JOG: 'NB44-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'CE',
    SHORT_NAME: 'Sri Lanka',
    FULL_NAME: 'Democratic Socialist Republic of Sri Lanka',
    MOD_DATE: '2007-02-28',
    ISO3136: 'LK',
  },
  {
    LAT: 16,
    LONG: 30,
    DMS_LAT: 160000,
    DMS_LONG: 300000,
    MGRS: '36QSC7890071254',
    JOG: 'NE36-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SU',
    SHORT_NAME: 'Sudan',
    FULL_NAME: 'Republic of the Sudan',
    MOD_DATE: '2011-07-12',
    ISO3136: 'SD',
  },
  {
    LAT: 4,
    LONG: -56,
    DMS_LAT: 40000,
    DMS_LONG: -560000,
    MGRS: '21NXE1101142195',
    JOG: 'NB21-15',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NS',
    SHORT_NAME: 'Suriname',
    FULL_NAME: 'Republic of Suriname',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SR',
  },
  {
    LAT: 78,
    LONG: 20,
    DMS_LAT: 780000,
    DMS_LONG: 200000,
    MGRS: '33XXG1591563320',
    JOG: 'NT33-06',
    DSG: 'ADM1',
    AFFIL: 'NO',
    FIPS10: 'SV',
    SHORT_NAME: 'Svalbard',
    FULL_NAME: 'Svalbard and Jan Mayen',
    MOD_DATE: '1996/11/29',
    ISO3136: 'SJ',
  },
  {
    LAT: -26.5,
    LONG: 31.5,
    DMS_LAT: -263000,
    DMS_LONG: 313000,
    MGRS: '36JUR5051468069',
    JOG: 'SG36-10',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'WZ',
    SHORT_NAME: 'Swaziland',
    FULL_NAME: 'Kingdom of Swaziland',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SZ',
  },
  {
    LAT: 62,
    LONG: 15,
    DMS_LAT: 620000,
    DMS_LONG: 150000,
    MGRS: '33VWJ0000074180',
    JOG: 'NP33-06',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SW',
    SHORT_NAME: 'Sweden',
    FULL_NAME: 'Kingdom of Sweden',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SE',
  },
  {
    LAT: 47,
    LONG: 8,
    DMS_LAT: 470000,
    DMS_LONG: 80000,
    MGRS: '32TMT2397505649',
    JOG: 'NL32-02',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SZ',
    SHORT_NAME: 'Switzerland',
    FULL_NAME: 'Swiss Confederation',
    MOD_DATE: '2007-02-28',
    ISO3136: 'CH',
  },
  {
    LAT: 35,
    LONG: 38,
    DMS_LAT: 350000,
    DMS_LONG: 380000,
    MGRS: '37SDU0874773500',
    JOG: 'NI37-02',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'SY',
    SHORT_NAME: 'Syria',
    FULL_NAME: 'Syrian Arab Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'SY',
  },
  {
    LAT: 24,
    LONG: 121,
    DMS_LAT: 240000,
    DMS_LONG: 1210000,
    MGRS: '51RTG9655055671',
    JOG: 'NG51-13',
    DSG: 'PCL',
    AFFIL: '',
    FIPS10: 'TW',
    SHORT_NAME: 'Taiwan',
    FULL_NAME: 'Taiwan Province of China',
    MOD_DATE: '2011/03/02',
    ISO3136: 'TW',
  },
  {
    LAT: 39,
    LONG: 71,
    DMS_LAT: 390000,
    DMS_LONG: 710000,
    MGRS: '42SXJ7319118679',
    JOG: 'NJ42-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TI',
    SHORT_NAME: 'Tajikistan',
    FULL_NAME: 'Republic of Tajikistan',
    MOD_DATE: '2007-02-28',
    ISO3136: 'TJ',
  },
  {
    LAT: -6,
    LONG: 35,
    DMS_LAT: -60000,
    DMS_LONG: 350000,
    MGRS: '36MYU2138336391',
    JOG: 'SB36-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TZ',
    SHORT_NAME: 'Tanzania',
    FULL_NAME: 'United Republic of Tanzania',
    MOD_DATE: '2011-11-29',
    ISO3136: 'TZ',
  },
  {
    LAT: 15,
    LONG: 100,
    DMS_LAT: 150000,
    DMS_LONG: 1000000,
    MGRS: '47PPS0751258569',
    JOG: 'ND47-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TH',
    SHORT_NAME: 'Thailand',
    FULL_NAME: 'Kingdom of Thailand',
    MOD_DATE: '2007-02-28',
    ISO3136: 'TH',
  },
  {
    LAT: -8.833333,
    LONG: 125.75,
    DMS_LAT: -85000,
    DMS_LONG: 1254500,
    MGRS: '51LZL0251222459',
    JOG: 'SC51-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TT',
    SHORT_NAME: 'Timor-Leste',
    FULL_NAME: 'Democratic Republic of Timor-Leste',
    MOD_DATE: '2007-02-28',
    ISO3136: 'TL',
  },
  {
    LAT: 8,
    LONG: 1.166667,
    DMS_LAT: 80000,
    DMS_LONG: 11000,
    MGRS: '31PBJ9793584748',
    JOG: 'NC31-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TO',
    SHORT_NAME: 'Togo',
    FULL_NAME: 'Togolese Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'TG',
  },
  {
    LAT: -9,
    LONG: -171.75,
    DMS_LAT: -90000,
    DMS_LONG: -1714500,
    MGRS: '02LMR1756205063',
    JOG: 'SC02-02',
    DSG: 'PCLD',
    AFFIL: 'NZ',
    FIPS10: 'TL',
    SHORT_NAME: 'Tokelau',
    FULL_NAME: 'Tokelau',
    MOD_DATE: '1993-12-22',
    ISO3136: 'TK',
  },
  {
    LAT: -20,
    LONG: -175,
    DMS_LAT: -200000,
    DMS_LONG: -1750000,
    MGRS: '01KGT0924387269',
    JOG: 'SE01-16',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TN',
    SHORT_NAME: 'Tonga',
    FULL_NAME: 'Kingdom of Tonga',
    MOD_DATE: '2007-02-28',
    ISO3136: 'TO',
  },
  {
    LAT: 11,
    LONG: -61,
    DMS_LAT: 110000,
    DMS_LONG: -610000,
    MGRS: '20PQT1852916707',
    JOG: 'NC20-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TD',
    SHORT_NAME: 'Trinidad and Tobago',
    FULL_NAME: 'Republic of Trinidad and Tobago',
    MOD_DATE: '2007-02-28',
    ISO3136: 'TT',
  },
  {
    LAT: -15.866667,
    LONG: 54.416667,
    DMS_LAT: -155200,
    DMS_LONG: 542500,
    MGRS: '40LBH2334244107',
    JOG: 'SD40-13',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'TE',
    SHORT_NAME: 'Tromelin Island',
    FULL_NAME: 'Tromelin Island',
    MOD_DATE: '1997-07-10',
    ISO3136: '',
  },
  {
    LAT: 34,
    LONG: 9,
    DMS_LAT: 340000,
    DMS_LONG: 90000,
    MGRS: '32SNC0000062156',
    JOG: 'NI32-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TS',
    SHORT_NAME: 'Tunisia',
    FULL_NAME: 'Tunisian Republic',
    MOD_DATE: '2007-02-28',
    ISO3136: 'TN',
  },
  {
    LAT: 39.059012,
    LONG: 34.911546,
    DMS_LAT: 390332,
    DMS_LONG: 345442,
    MGRS: '36SXJ6539325064',
    JOG: 'NJ36-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TU',
    SHORT_NAME: 'Turkey',
    FULL_NAME: 'Republic of Turkey',
    MOD_DATE: '2011-05-23',
    ISO3136: 'TR',
  },
  {
    LAT: 40,
    LONG: 60,
    DMS_LAT: 400000,
    DMS_LONG: 600000,
    MGRS: '41TKE4390032069',
    JOG: 'NK41-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TX',
    SHORT_NAME: 'Turkmenistan',
    FULL_NAME: 'Turkmenistan',
    MOD_DATE: '2001-11-08',
    ISO3136: 'TM',
  },
  {
    LAT: 21.733333,
    LONG: -71.583333,
    DMS_LAT: 214400,
    DMS_LONG: -713500,
    MGRS: '19QBE3278405543',
    JOG: 'NF19-09',
    DSG: 'PCLD',
    AFFIL: 'UK',
    FIPS10: 'TK',
    SHORT_NAME: 'Turks and Caicos Islands',
    FULL_NAME: 'Turks and Caicos Islands',
    MOD_DATE: '1993-12-22',
    ISO3136: 'TC',
  },
  {
    LAT: -8,
    LONG: 178,
    DMS_LAT: -80000,
    DMS_LONG: 1780000,
    MGRS: '60MXS1020515568',
    JOG: 'SC60-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'TV',
    SHORT_NAME: 'Tuvalu',
    FULL_NAME: 'Tuvalu',
    MOD_DATE: '1993-12-22',
    ISO3136: 'TV',
  },
  {
    LAT: 2,
    LONG: 33,
    DMS_LAT: 20000,
    DMS_LONG: 330000,
    MGRS: '36NWH0000021061',
    JOG: 'NA36-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'UG',
    SHORT_NAME: 'Uganda',
    FULL_NAME: 'Republic of Uganda',
    MOD_DATE: '2011-07-12',
    ISO3136: 'UG',
  },
  {
    LAT: 49,
    LONG: 32,
    DMS_LAT: 490000,
    DMS_LONG: 320000,
    MGRS: '36UVV2685827938',
    JOG: 'NM36-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'UP',
    SHORT_NAME: 'Ukraine',
    FULL_NAME: 'Ukraine',
    MOD_DATE: '1998-06-05',
    ISO3136: 'UA',
  },
  {
    LAT: 24,
    LONG: 54,
    DMS_LAT: 240000,
    DMS_LONG: 540000,
    MGRS: '40RAM9477357479',
    JOG: 'NG40-13',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'AE',
    SHORT_NAME: 'United Arab Emirates',
    FULL_NAME: 'United Arab Emirates',
    MOD_DATE: '1999-06-15',
    ISO3136: 'AE',
  },
  {
    LAT: 54,
    LONG: -4,
    DMS_LAT: 540000,
    DMS_LONG: -40000,
    MGRS: '30UVE3445183984',
    JOG: 'NN30-05',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'UK',
    SHORT_NAME: 'United Kingdom',
    FULL_NAME: 'United Kingdom of Great Britain and Northern Ireland',
    MOD_DATE: '2007-02-28',
    ISO3136: 'GB',
  },
  {
    LAT: 39.828175,
    LONG: -98.5795,
    DMS_LAT: 394941,
    DMS_LONG: -983446,
    MGRS: 1890467,
    JOG: '',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'US',
    SHORT_NAME: 'United States',
    FULL_NAME: 'United States of America',
    MOD_DATE: '2001-01-10',
    ISO3136: 'US',
  },
  {
    LAT: -33,
    LONG: -56,
    DMS_LAT: -330000,
    DMS_LONG: -560000,
    MGRS: '21HWD9341848269',
    JOG: 'SI21-03',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'UY',
    SHORT_NAME: 'Uruguay',
    FULL_NAME: 'Oriental Republic of Uruguay',
    MOD_DATE: '2007-02-28',
    ISO3136: 'UY',
  },
  {
    LAT: 5.8811111,
    LONG: -162.0725,
    DMS_LAT: 55252,
    DMS_LONG: -1620421,
    MGRS: 1878752,
    JOG: '',
    DSG: 'PCLS',
    AFFIL: 'US',
    FIPS10: 'UM',
    SHORT_NAME: 'US Minor Outlying Islands',
    FULL_NAME: 'United States Minor Outlying Islands',
    MOD_DATE: '2000-09-20',
    ISO3136: 'UM',
  },
  {
    LAT: 18.3482891,
    LONG: -64.9834807,
    DMS_LAT: 182054,
    DMS_LONG: -645901,
    MGRS: 1802710,
    JOG: '',
    DSG: 'PCLD',
    AFFIL: 'US',
    FIPS10: 'VI',
    SHORT_NAME: 'US Virgin Islands',
    FULL_NAME: 'United States Virgin Islands',
    MOD_DATE: '1998-10-06',
    ISO3136: 'VI',
  },
  {
    LAT: 41.707542,
    LONG: 63.84911,
    DMS_LAT: 414227,
    DMS_LONG: 635057,
    MGRS: '41TNG7064317654',
    JOG: 'NK41-08',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'UZ',
    SHORT_NAME: 'Uzbekistan',
    FULL_NAME: 'Republic of Uzbekistan',
    MOD_DATE: '2011-05-05',
    ISO3136: 'UZ',
  },
  {
    LAT: -16,
    LONG: 167,
    DMS_LAT: -160000,
    DMS_LONG: 1670000,
    MGRS: '58LGH1402030035',
    JOG: 'SD58-16',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'NH',
    SHORT_NAME: 'Vanuatu',
    FULL_NAME: 'Republic of Vanuatu',
    MOD_DATE: '2007-02-28',
    ISO3136: 'VU',
  },
  {
    LAT: 41.9,
    LONG: 12.45,
    DMS_LAT: 415400,
    DMS_LONG: 122700,
    MGRS: '33TTG8847741818',
    JOG: 'NK33-07',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'VT',
    SHORT_NAME: 'Vatican City',
    FULL_NAME: 'State of the Vatican City',
    MOD_DATE: '2007-02-28',
    ISO3136: 'VA',
  },
  {
    LAT: 8,
    LONG: -66,
    DMS_LAT: 80000,
    DMS_LONG: -660000,
    MGRS: '20PJP6925685504',
    JOG: 'NC20-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'VE',
    SHORT_NAME: 'Venezuela',
    FULL_NAME: 'Bolivarian Republic of Venezuela',
    MOD_DATE: '2007-02-28',
    ISO3136: 'VE',
  },
  {
    LAT: 16.166667,
    LONG: 107.833333,
    DMS_LAT: 161000,
    DMS_LONG: 1075000,
    MGRS: '48QZC0299589458',
    JOG: 'NE48-16',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'VM',
    SHORT_NAME: 'Vietnam',
    FULL_NAME: 'Socialist Republic of Vietnam',
    MOD_DATE: '2007-02-28',
    ISO3136: 'VN',
  },
  {
    LAT: -13.3,
    LONG: -176.2,
    DMS_LAT: -131800,
    DMS_LONG: -1761200,
    MGRS: '01LEF8665029549',
    JOG: 'SD01-07',
    DSG: 'PCLD',
    AFFIL: 'FR',
    FIPS10: 'WF',
    SHORT_NAME: 'Wallis and Futuna',
    FULL_NAME: 'Wallis and Futuna',
    MOD_DATE: '2010-11-18',
    ISO3136: 'WF',
  },
  {
    LAT: 31.666667,
    LONG: 35.25,
    DMS_LAT: 314000,
    DMS_LONG: 351500,
    MGRS: '36RYA1331505689',
    JOG: 'NH36-04',
    DSG: 'TERR',
    AFFIL: '',
    FIPS10: 'WE',
    SHORT_NAME: 'West Bank',
    FULL_NAME: 'West Bank',
    MOD_DATE: '1993-12-16',
    ISO3136: 'PS',
  },
  {
    LAT: 25,
    LONG: -13.5,
    DMS_LAT: 250000,
    DMS_LONG: -133000,
    MGRS: '28RFN5137665785',
    JOG: 'NG28-12',
    DSG: 'PCL',
    AFFIL: '',
    FIPS10: 'WI',
    SHORT_NAME: 'Western Sahara',
    FULL_NAME: 'Western Sahara',
    MOD_DATE: '2007/02/28',
    ISO3136: 'EH',
  },
  {
    LAT: 15.5,
    LONG: 47.5,
    DMS_LAT: 153000,
    DMS_LONG: 473000,
    MGRS: '38PQC6820715194',
    JOG: 'ND38-04',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'YM',
    SHORT_NAME: 'Yemen',
    FULL_NAME: 'Republic of Yemen',
    MOD_DATE: '2010-12-09',
    ISO3136: 'YE',
  },
  {
    LAT: -15,
    LONG: 30,
    DMS_LAT: -150000,
    DMS_LONG: 300000,
    MGRS: '36LSJ7734939486',
    JOG: 'SD36-09',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'ZA',
    SHORT_NAME: 'Zambia',
    FULL_NAME: 'Republic of Zambia',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ZM',
  },
  {
    LAT: -19,
    LONG: 29,
    DMS_LAT: -190000,
    DMS_LONG: 290000,
    MGRS: '35KQU1053497976',
    JOG: 'SE35-12',
    DSG: 'PCLI',
    AFFIL: '',
    FIPS10: 'ZI',
    SHORT_NAME: 'Zimbabwe',
    FULL_NAME: 'Republic of Zimbabwe',
    MOD_DATE: '2007-02-28',
    ISO3136: 'ZW',
  },
];
