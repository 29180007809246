export const pokemonsData = [
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDE=',
    image: 'https://img.pokemondb.net/artwork/bulbasaur.jpg',
    maxHP: 1071,
    name: 'Bulbasaur',
    number: '001',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDI=',
    image: 'https://img.pokemondb.net/artwork/ivysaur.jpg',
    maxHP: 1632,
    name: 'Ivysaur',
    number: '002',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDM=',
    image: 'https://img.pokemondb.net/artwork/venusaur.jpg',
    maxHP: 2580,
    name: 'Venusaur',
    number: '003',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDQ=',
    image: 'https://img.pokemondb.net/artwork/charmander.jpg',
    maxHP: 955,
    name: 'Charmander',
    number: '004',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDU=',
    image: 'https://img.pokemondb.net/artwork/charmeleon.jpg',
    maxHP: 1557,
    name: 'Charmeleon',
    number: '005',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDY=',
    image: 'https://img.pokemondb.net/artwork/charizard.jpg',
    maxHP: 2602,
    name: 'Charizard',
    number: '006',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDc=',
    image: 'https://img.pokemondb.net/artwork/squirtle.jpg',
    maxHP: 1008,
    name: 'Squirtle',
    number: '007',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDg=',
    image: 'https://img.pokemondb.net/artwork/wartortle.jpg',
    maxHP: 1582,
    name: 'Wartortle',
    number: '008',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMDk=',
    image: 'https://img.pokemondb.net/artwork/blastoise.jpg',
    maxHP: 2542,
    name: 'Blastoise',
    number: '009',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTA=',
    image: 'https://img.pokemondb.net/artwork/caterpie.jpg',
    maxHP: 443,
    name: 'Caterpie',
    number: '010',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTE=',
    image: 'https://img.pokemondb.net/artwork/metapod.jpg',
    maxHP: 477,
    name: 'Metapod',
    number: '011',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTI=',
    image: 'https://img.pokemondb.net/artwork/butterfree.jpg',
    maxHP: 1454,
    name: 'Butterfree',
    number: '012',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTM=',
    image: 'https://img.pokemondb.net/artwork/weedle.jpg',
    maxHP: 449,
    name: 'Weedle',
    number: '013',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTQ=',
    image: 'https://img.pokemondb.net/artwork/kakuna.jpg',
    maxHP: 485,
    name: 'Kakuna',
    number: '014',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTU=',
    image: 'https://img.pokemondb.net/artwork/beedrill.jpg',
    maxHP: 1439,
    name: 'Beedrill',
    number: '015',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTY=',
    image: 'https://img.pokemondb.net/artwork/pidgey.jpg',
    maxHP: 679,
    name: 'Pidgey',
    number: '016',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTc=',
    image: 'https://img.pokemondb.net/artwork/pidgeotto.jpg',
    maxHP: 1223,
    name: 'Pidgeotto',
    number: '017',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTg=',
    image: 'https://img.pokemondb.net/artwork/pidgeot.jpg',
    maxHP: 2091,
    name: 'Pidgeot',
    number: '018',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMTk=',
    image: 'https://img.pokemondb.net/artwork/rattata.jpg',
    maxHP: 581,
    name: 'Rattata',
    number: '019',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjA=',
    image: 'https://img.pokemondb.net/artwork/raticate.jpg',
    maxHP: 1444,
    name: 'Raticate',
    number: '020',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjE=',
    image: 'https://img.pokemondb.net/artwork/spearow.jpg',
    maxHP: 686,
    name: 'Spearow',
    number: '021',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjI=',
    image: 'https://img.pokemondb.net/artwork/fearow.jpg',
    maxHP: 1746,
    name: 'Fearow',
    number: '022',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjM=',
    image: 'https://img.pokemondb.net/artwork/ekans.jpg',
    maxHP: 824,
    name: 'Ekans',
    number: '023',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjQ=',
    image: 'https://img.pokemondb.net/artwork/arbok.jpg',
    maxHP: 1767,
    name: 'Arbok',
    number: '024',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjU=',
    image: 'https://img.pokemondb.net/artwork/pikachu.jpg',
    maxHP: 887,
    name: 'Pikachu',
    number: '025',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjY=',
    image: 'https://img.pokemondb.net/artwork/raichu.jpg',
    maxHP: 2028,
    name: 'Raichu',
    number: '026',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjc=',
    image: 'https://img.pokemondb.net/artwork/sandshrew.jpg',
    maxHP: 798,
    name: 'Sandshrew',
    number: '027',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjg=',
    image: 'https://img.pokemondb.net/artwork/sandslash.jpg',
    maxHP: 1810,
    name: 'Sandslash',
    number: '028',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMjk=',
    image: 'https://img.pokemondb.net/artwork/nidoran-f.jpg',
    maxHP: 876,
    name: 'Nidoran-F',
    number: '029',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzA=',
    image: 'https://img.pokemondb.net/artwork/nidorina.jpg',
    maxHP: 1404,
    name: 'Nidorina',
    number: '030',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzE=',
    image: 'https://img.pokemondb.net/artwork/nidoqueen.jpg',
    maxHP: 2485,
    name: 'Nidoqueen',
    number: '031',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzI=',
    image: 'https://img.pokemondb.net/artwork/nidoran-m.jpg',
    maxHP: 843,
    name: 'Nidoran-M',
    number: '032',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzM=',
    image: 'https://img.pokemondb.net/artwork/nidorino.jpg',
    maxHP: 1372,
    name: 'Nidorino',
    number: '033',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzQ=',
    image: 'https://img.pokemondb.net/artwork/nidoking.jpg',
    maxHP: 2475,
    name: 'Nidoking',
    number: '034',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzU=',
    image: 'https://img.pokemondb.net/artwork/clefairy.jpg',
    maxHP: 1200,
    name: 'Clefairy',
    number: '035',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzY=',
    image: 'https://img.pokemondb.net/artwork/clefable.jpg',
    maxHP: 2397,
    name: 'Clefable',
    number: '036',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzc=',
    image: 'https://img.pokemondb.net/artwork/vulpix.jpg',
    maxHP: 831,
    name: 'Vulpix',
    number: '037',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzg=',
    image: 'https://img.pokemondb.net/artwork/ninetales.jpg',
    maxHP: 2188,
    name: 'Ninetales',
    number: '038',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowMzk=',
    image: 'https://img.pokemondb.net/artwork/jigglypuff.jpg',
    maxHP: 917,
    name: 'Jigglypuff',
    number: '039',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDA=',
    image: 'https://img.pokemondb.net/artwork/wigglytuff.jpg',
    maxHP: 2177,
    name: 'Wigglytuff',
    number: '040',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDE=',
    image: 'https://img.pokemondb.net/artwork/zubat.jpg',
    maxHP: 642,
    name: 'Zubat',
    number: '041',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDI=',
    image: 'https://img.pokemondb.net/artwork/golbat.jpg',
    maxHP: 1921,
    name: 'Golbat',
    number: '042',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDM=',
    image: 'https://img.pokemondb.net/artwork/oddish.jpg',
    maxHP: 1148,
    name: 'Oddish',
    number: '043',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDQ=',
    image: 'https://img.pokemondb.net/artwork/gloom.jpg',
    maxHP: 1689,
    name: 'Gloom',
    number: '044',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDU=',
    image: 'https://img.pokemondb.net/artwork/vileplume.jpg',
    maxHP: 2492,
    name: 'Vileplume',
    number: '045',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDY=',
    image: 'https://img.pokemondb.net/artwork/paras.jpg',
    maxHP: 916,
    name: 'Paras',
    number: '046',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDc=',
    image: 'https://img.pokemondb.net/artwork/parasect.jpg',
    maxHP: 1747,
    name: 'Parasect',
    number: '047',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDg=',
    image: 'https://img.pokemondb.net/artwork/venonat.jpg',
    maxHP: 1029,
    name: 'Venonat',
    number: '048',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNDk=',
    image: 'https://img.pokemondb.net/artwork/venomoth.jpg',
    maxHP: 1890,
    name: 'Venomoth',
    number: '049',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTA=',
    image: 'https://img.pokemondb.net/artwork/diglett.jpg',
    maxHP: 456,
    name: 'Diglett',
    number: '050',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTE=',
    image: 'https://img.pokemondb.net/artwork/dugtrio.jpg',
    maxHP: 1168,
    name: 'Dugtrio',
    number: '051',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTI=',
    image: 'https://img.pokemondb.net/artwork/meowth.jpg',
    maxHP: 756,
    name: 'Meowth',
    number: '052',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTM=',
    image: 'https://img.pokemondb.net/artwork/persian.jpg',
    maxHP: 1631,
    name: 'Persian',
    number: '053',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTQ=',
    image: 'https://img.pokemondb.net/artwork/psyduck.jpg',
    maxHP: 1109,
    name: 'Psyduck',
    number: '054',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTU=',
    image: 'https://img.pokemondb.net/artwork/golduck.jpg',
    maxHP: 2386,
    name: 'Golduck',
    number: '055',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTY=',
    image: 'https://img.pokemondb.net/artwork/mankey.jpg',
    maxHP: 878,
    name: 'Mankey',
    number: '056',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTc=',
    image: 'https://img.pokemondb.net/artwork/primeape.jpg',
    maxHP: 1864,
    name: 'Primeape',
    number: '057',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTg=',
    image: 'https://img.pokemondb.net/artwork/growlithe.jpg',
    maxHP: 1335,
    name: 'Growlithe',
    number: '058',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNTk=',
    image: 'https://img.pokemondb.net/artwork/arcanine.jpg',
    maxHP: 2983,
    name: 'Arcanine',
    number: '059',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjA=',
    image: 'https://img.pokemondb.net/artwork/poliwag.jpg',
    maxHP: 795,
    name: 'Poliwag',
    number: '060',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjE=',
    image: 'https://img.pokemondb.net/artwork/poliwhirl.jpg',
    maxHP: 1340,
    name: 'Poliwhirl',
    number: '061',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjI=',
    image: 'https://img.pokemondb.net/artwork/poliwrath.jpg',
    maxHP: 2505,
    name: 'Poliwrath',
    number: '062',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjM=',
    image: 'https://img.pokemondb.net/artwork/abra.jpg',
    maxHP: 600,
    name: 'Abra',
    number: '063',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjQ=',
    image: 'https://img.pokemondb.net/artwork/kadabra.jpg',
    maxHP: 1131,
    name: 'Kadabra',
    number: '064',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjU=',
    image: 'https://img.pokemondb.net/artwork/alakazam.jpg',
    maxHP: 1813,
    name: 'Alakazam',
    number: '065',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjY=',
    image: 'https://img.pokemondb.net/artwork/machop.jpg',
    maxHP: 1089,
    name: 'Machop',
    number: '066',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjc=',
    image: 'https://img.pokemondb.net/artwork/machoke.jpg',
    maxHP: 1760,
    name: 'Machoke',
    number: '067',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjg=',
    image: 'https://img.pokemondb.net/artwork/machamp.jpg',
    maxHP: 2594,
    name: 'Machamp',
    number: '068',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNjk=',
    image: 'https://img.pokemondb.net/artwork/bellsprout.jpg',
    maxHP: 1117,
    name: 'Bellsprout',
    number: '069',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzA=',
    image: 'https://img.pokemondb.net/artwork/weepinbell.jpg',
    maxHP: 1723,
    name: 'Weepinbell',
    number: '070',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzE=',
    image: 'https://img.pokemondb.net/artwork/victreebel.jpg',
    maxHP: 2530,
    name: 'Victreebel',
    number: '071',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzI=',
    image: 'https://img.pokemondb.net/artwork/tentacool.jpg',
    maxHP: 905,
    name: 'Tentacool',
    number: '072',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzM=',
    image: 'https://img.pokemondb.net/artwork/tentacruel.jpg',
    maxHP: 2220,
    name: 'Tentacruel',
    number: '073',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzQ=',
    image: 'https://img.pokemondb.net/artwork/geodude.jpg',
    maxHP: 849,
    name: 'Geodude',
    number: '074',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzU=',
    image: 'https://img.pokemondb.net/artwork/graveler.jpg',
    maxHP: 1433,
    name: 'Graveler',
    number: '075',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzY=',
    image: 'https://img.pokemondb.net/artwork/golem.jpg',
    maxHP: 2303,
    name: 'Golem',
    number: '076',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzc=',
    image: 'https://img.pokemondb.net/artwork/ponyta.jpg',
    maxHP: 1516,
    name: 'Ponyta',
    number: '077',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzg=',
    image: 'https://img.pokemondb.net/artwork/rapidash.jpg',
    maxHP: 2199,
    name: 'Rapidash',
    number: '078',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowNzk=',
    image: 'https://img.pokemondb.net/artwork/slowpoke.jpg',
    maxHP: 1218,
    name: 'Slowpoke',
    number: '079',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODA=',
    image: 'https://img.pokemondb.net/artwork/slowbro.jpg',
    maxHP: 2597,
    name: 'Slowbro',
    number: '080',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODE=',
    image: 'https://img.pokemondb.net/artwork/magnemite.jpg',
    maxHP: 890,
    name: 'Magnemite',
    number: '081',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODI=',
    image: 'https://img.pokemondb.net/artwork/magneton.jpg',
    maxHP: 1879,
    name: 'Magneton',
    number: '082',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODM=',
    image: 'https://img.pokemondb.net/artwork/farfetchd.jpg',
    maxHP: 1263,
    name: "Farfetch'd",
    number: '083',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODQ=',
    image: 'https://img.pokemondb.net/artwork/doduo.jpg',
    maxHP: 855,
    name: 'Doduo',
    number: '084',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODU=',
    image: 'https://img.pokemondb.net/artwork/dodrio.jpg',
    maxHP: 1836,
    name: 'Dodrio',
    number: '085',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODY=',
    image: 'https://img.pokemondb.net/artwork/seel.jpg',
    maxHP: 1107,
    name: 'Seel',
    number: '086',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODc=',
    image: 'https://img.pokemondb.net/artwork/dewgong.jpg',
    maxHP: 2145,
    name: 'Dewgong',
    number: '087',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODg=',
    image: 'https://img.pokemondb.net/artwork/grimer.jpg',
    maxHP: 1284,
    name: 'Grimer',
    number: '088',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowODk=',
    image: 'https://img.pokemondb.net/artwork/muk.jpg',
    maxHP: 2602,
    name: 'Muk',
    number: '089',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTA=',
    image: 'https://img.pokemondb.net/artwork/shellder.jpg',
    maxHP: 822,
    name: 'Shellder',
    number: '090',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTE=',
    image: 'https://img.pokemondb.net/artwork/cloyster.jpg',
    maxHP: 2052,
    name: 'Cloyster',
    number: '091',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTI=',
    image: 'https://img.pokemondb.net/artwork/gastly.jpg',
    maxHP: 804,
    name: 'Gastly',
    number: '092',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTM=',
    image: 'https://img.pokemondb.net/artwork/haunter.jpg',
    maxHP: 1380,
    name: 'Haunter',
    number: '093',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTQ=',
    image: 'https://img.pokemondb.net/artwork/gengar.jpg',
    maxHP: 2078,
    name: 'Gengar',
    number: '094',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTU=',
    image: 'https://img.pokemondb.net/artwork/onix.jpg',
    maxHP: 857,
    name: 'Onix',
    number: '095',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTY=',
    image: 'https://img.pokemondb.net/artwork/drowzee.jpg',
    maxHP: 1075,
    name: 'Drowzee',
    number: '096',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTc=',
    image: 'https://img.pokemondb.net/artwork/hypno.jpg',
    maxHP: 2184,
    name: 'Hypno',
    number: '097',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTg=',
    image: 'https://img.pokemondb.net/artwork/krabby.jpg',
    maxHP: 792,
    name: 'Krabby',
    number: '098',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjowOTk=',
    image: 'https://img.pokemondb.net/artwork/kingler.jpg',
    maxHP: 1823,
    name: 'Kingler',
    number: '099',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDA=',
    image: 'https://img.pokemondb.net/artwork/voltorb.jpg',
    maxHP: 839,
    name: 'Voltorb',
    number: '100',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDE=',
    image: 'https://img.pokemondb.net/artwork/electrode.jpg',
    maxHP: 1646,
    name: 'Electrode',
    number: '101',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDI=',
    image: 'https://img.pokemondb.net/artwork/exeggcute.jpg',
    maxHP: 1099,
    name: 'Exeggcute',
    number: '102',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDM=',
    image: 'https://img.pokemondb.net/artwork/exeggutor.jpg',
    maxHP: 2955,
    name: 'Exeggutor',
    number: '103',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDQ=',
    image: 'https://img.pokemondb.net/artwork/cubone.jpg',
    maxHP: 1006,
    name: 'Cubone',
    number: '104',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDU=',
    image: 'https://img.pokemondb.net/artwork/marowak.jpg',
    maxHP: 1656,
    name: 'Marowak',
    number: '105',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDY=',
    image: 'https://img.pokemondb.net/artwork/hitmonlee.jpg',
    maxHP: 1492,
    name: 'Hitmonlee',
    number: '106',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDc=',
    image: 'https://img.pokemondb.net/artwork/hitmonchan.jpg',
    maxHP: 1516,
    name: 'Hitmonchan',
    number: '107',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDg=',
    image: 'https://img.pokemondb.net/artwork/lickitung.jpg',
    maxHP: 1626,
    name: 'Lickitung',
    number: '108',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMDk=',
    image: 'https://img.pokemondb.net/artwork/koffing.jpg',
    maxHP: 1151,
    name: 'Koffing',
    number: '109',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTA=',
    image: 'https://img.pokemondb.net/artwork/weezing.jpg',
    maxHP: 2250,
    name: 'Weezing',
    number: '110',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTE=',
    image: 'https://img.pokemondb.net/artwork/rhyhorn.jpg',
    maxHP: 1182,
    name: 'Rhyhorn',
    number: '111',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTI=',
    image: 'https://img.pokemondb.net/artwork/rhydon.jpg',
    maxHP: 2243,
    name: 'Rhydon',
    number: '112',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTM=',
    image: 'https://img.pokemondb.net/artwork/chansey.jpg',
    maxHP: 675,
    name: 'Chansey',
    number: '113',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTQ=',
    image: 'https://img.pokemondb.net/artwork/tangela.jpg',
    maxHP: 1739,
    name: 'Tangela',
    number: '114',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTU=',
    image: 'https://img.pokemondb.net/artwork/kangaskhan.jpg',
    maxHP: 2043,
    name: 'Kangaskhan',
    number: '115',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTY=',
    image: 'https://img.pokemondb.net/artwork/horsea.jpg',
    maxHP: 794,
    name: 'Horsea',
    number: '116',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTc=',
    image: 'https://img.pokemondb.net/artwork/seadra.jpg',
    maxHP: 1713,
    name: 'Seadra',
    number: '117',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTg=',
    image: 'https://img.pokemondb.net/artwork/goldeen.jpg',
    maxHP: 965,
    name: 'Goldeen',
    number: '118',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMTk=',
    image: 'https://img.pokemondb.net/artwork/seaking.jpg',
    maxHP: 2043,
    name: 'Seaking',
    number: '119',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjA=',
    image: 'https://img.pokemondb.net/artwork/staryu.jpg',
    maxHP: 937,
    name: 'Staryu',
    number: '120',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjE=',
    image: 'https://img.pokemondb.net/artwork/starmie.jpg',
    maxHP: 2182,
    name: 'Starmie',
    number: '121',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjI=',
    image: 'https://img.pokemondb.net/artwork/mr-mime.jpg',
    maxHP: 1494,
    name: 'Mr. Mime',
    number: '122',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjM=',
    image: 'https://img.pokemondb.net/artwork/scyther.jpg',
    maxHP: 2073,
    name: 'Scyther',
    number: '123',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjQ=',
    image: 'https://img.pokemondb.net/artwork/jynx.jpg',
    maxHP: 1716,
    name: 'Jynx',
    number: '124',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjU=',
    image: 'https://img.pokemondb.net/artwork/electabuzz.jpg',
    maxHP: 2119,
    name: 'Electabuzz',
    number: '125',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjY=',
    image: 'https://img.pokemondb.net/artwork/magmar.jpg',
    maxHP: 2265,
    name: 'Magmar',
    number: '126',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjc=',
    image: 'https://img.pokemondb.net/artwork/pinsir.jpg',
    maxHP: 2121,
    name: 'Pinsir',
    number: '127',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjg=',
    image: 'https://img.pokemondb.net/artwork/tauros.jpg',
    maxHP: 1844,
    name: 'Tauros',
    number: '128',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMjk=',
    image: 'https://img.pokemondb.net/artwork/magikarp.jpg',
    maxHP: 262,
    name: 'Magikarp',
    number: '129',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzA=',
    image: 'https://img.pokemondb.net/artwork/gyarados.jpg',
    maxHP: 2688,
    name: 'Gyarados',
    number: '130',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzE=',
    image: 'https://img.pokemondb.net/artwork/lapras.jpg',
    maxHP: 2980,
    name: 'Lapras',
    number: '131',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzI=',
    image: 'https://img.pokemondb.net/artwork/ditto.jpg',
    maxHP: 919,
    name: 'Ditto',
    number: '132',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzM=',
    image: 'https://img.pokemondb.net/artwork/eevee.jpg',
    maxHP: 1077,
    name: 'Eevee',
    number: '133',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzQ=',
    image: 'https://img.pokemondb.net/artwork/vaporeon.jpg',
    maxHP: 2816,
    name: 'Vaporeon',
    number: '134',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzU=',
    image: 'https://img.pokemondb.net/artwork/jolteon.jpg',
    maxHP: 2140,
    name: 'Jolteon',
    number: '135',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzY=',
    image: 'https://img.pokemondb.net/artwork/flareon.jpg',
    maxHP: 2643,
    name: 'Flareon',
    number: '136',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzc=',
    image: 'https://img.pokemondb.net/artwork/porygon.jpg',
    maxHP: 1691,
    name: 'Porygon',
    number: '137',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzg=',
    image: 'https://img.pokemondb.net/artwork/omanyte.jpg',
    maxHP: 1119,
    name: 'Omanyte',
    number: '138',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxMzk=',
    image: 'https://img.pokemondb.net/artwork/omastar.jpg',
    maxHP: 2233,
    name: 'Omastar',
    number: '139',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDA=',
    image: 'https://img.pokemondb.net/artwork/kabuto.jpg',
    maxHP: 1104,
    name: 'Kabuto',
    number: '140',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDE=',
    image: 'https://img.pokemondb.net/artwork/kabutops.jpg',
    maxHP: 2130,
    name: 'Kabutops',
    number: '141',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDI=',
    image: 'https://img.pokemondb.net/artwork/aerodactyl.jpg',
    maxHP: 2165,
    name: 'Aerodactyl',
    number: '142',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDM=',
    image: 'https://img.pokemondb.net/artwork/snorlax.jpg',
    maxHP: 3112,
    name: 'Snorlax',
    number: '143',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDQ=',
    image: 'https://img.pokemondb.net/artwork/articuno.jpg',
    maxHP: 2978,
    name: 'Articuno',
    number: '144',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDU=',
    image: 'https://img.pokemondb.net/artwork/zapdos.jpg',
    maxHP: 3114,
    name: 'Zapdos',
    number: '145',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDY=',
    image: 'https://img.pokemondb.net/artwork/moltres.jpg',
    maxHP: 3240,
    name: 'Moltres',
    number: '146',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDc=',
    image: 'https://img.pokemondb.net/artwork/dratini.jpg',
    maxHP: 983,
    name: 'Dratini',
    number: '147',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDg=',
    image: 'https://img.pokemondb.net/artwork/dragonair.jpg',
    maxHP: 1747,
    name: 'Dragonair',
    number: '148',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNDk=',
    image: 'https://img.pokemondb.net/artwork/dragonite.jpg',
    maxHP: 3500,
    name: 'Dragonite',
    number: '149',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNTA=',
    image: 'https://img.pokemondb.net/artwork/mewtwo.jpg',
    maxHP: 4144,
    name: 'Mewtwo',
    number: '150',
  },
  {
    __typename: 'Pokemon',
    id: 'UG9rZW1vbjoxNTE=',
    image: 'https://img.pokemondb.net/artwork/mew.jpg',
    maxHP: 3299,
    name: 'Mew',
    number: '151',
  },
];
